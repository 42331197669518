.footer_box {
    background-color: #000000;
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.footer_box_item1, .footer_box_item2, .footer_box_item3 {
    color: #ffffff;
}

.footer_box_item1, .footer_box_item3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.footer_box_item1 {
    padding-top: 1.5rem;
    padding-left: 1%;
    width: 26%;
}

.footer_box_item1 ul.list-container li {
    list-style-type: none;
}

.footer_box_item1 ul.list-container li a {
    font-size: 24px;
    color: #ffffff;
    list-style-type: none;
}

.footer_box_item3 {
    padding-right: 2.5%;
}

.footer_box_item3 .footer_box_sns {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.footer_box_item3 .footer_box_sns a {
    width: 15%;
    padding: 15px;
    color: #ffffff;
    font-size: 30px;
}

/* custom image footer_box_item2 */
.footer_box_item2 {
    width: 27%;
}
.contact__cus {
    width: 300px;
    background-size: 100%;
}
.contact__cus .form {
    width: 70%;
    margin-bottom: 1.1rem;
}
.contact__cus .form a[type="submit"] {
    padding: 9px;
    width: 210px;
}
.logo__footer img {
    width: 350px;
}

/*  */
.btn__footer_box_item2:hover {
    color: #212529;
    background-color: transparent;
    border-color: #ffff00;
}

.btn__footer_box_item2:not(:disabled):not(.disabled).active:focus, .btn__footer_box_item2:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
}

.btn__footer_box_item2:not(:disabled):not(.disabled).active, .btn__footer_box_item2:not(:disabled):not(.disabled):active {
    color: #212529;
    background-color: transparent;
    border-color: #ffff00;
}

.logo__footer {
    width: 100%;
}

.copyright {
    text-align: end;
    font-size: 19px;
    padding-top: 50px;
}

@media (max-width: 1256px) {
    .logo__footer img {
        width: 350px;
    }
}
@media (max-width: 1024px) {
    .footer_box {
        align-items: center;
    }
    .list-container {
        padding-left: 0.2rem;
    }
    .footer_box_item1 ul.list-container li a {
        font-size: 1.1rem;
    }
    .footer_box_item2 {
        padding-top: 1px;
    }
    .footer_box_item2.contact__cus {
        width: 32%;
        height: 348px;
    }
    .contact__cus .form a[type="submit"] {
        width: 167px;
    }
    .logo__footer img {
        width: 300px;
    }
    .contact__cus {
        width: 290px;
    }
}

@media (max-width: 768px) {
    .footer_box_item1 ul.list-container li a {
        font-size: 0.9rem;
    }
    .footer_box_item2.contact__cus {
        width: 40%;
    }
    .contact__cus .form a[type="submit"] {
        font-size: 1rem;
    }
    .logo__footer img {
        width: 222px;
    }
    .footer_box_item2 {
        width: 40%;
    }
    .contact__cus {
        width: unset;
    }
    .footer_box_item3 .footer_box_sns a {
        width: 20%;
    }
}

@media (max-width: 568px) {
    .footer_box_item2.contact__cus {
        width: 76%;
    }
    .footer_box {
        flex-direction: column;
        align-items: center;
    }
    .logo__footer {
        text-align: center;
    }
    .logo__footer img {
        width: 75%;
    }
    .copyright {
        padding-top: 27px;
        text-align: center;
    }
    .footer_box_item1 {
        margin-top: 2rem;
        width: 100%;
        padding: 0;
    }
    .footer_box_item2 {
        width: 100%;
        display: contents;
    }
    .footer_box_item3 {
        margin-bottom: 2rem;
    }
    .list-container {
        padding: 0!important;
        width: 100%;
    }
    .list-container li {
        text-align: center;
    }
    .footer_box_item3 .footer_box_sns {
        justify-content: center;
    }
    .contact__cus {
        width: 300px;
    }
}

@media (max-width: 375px) {
    .footer_box_item2.contact__cus {
        width: 95%;
    }
    .footer_box_item2 a {
        font-size: 12px;
    }
}


