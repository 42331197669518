/* list video livesteam */
.list__video_livesteam_object {
    height: 425px;
    white-space: nowrap;
    width: 100%;
    display: block;
    position: relative;
}

.video_livesteam_scroll {
    overflow-x: scroll;
    overflow-y: hidden;
}

.list__video_livesteam_object {
    scrollbar-width: thin;
    scrollbar-color: #212529 #d3d3d3;
}

/* scroll */
.list__video_livesteam_object::-webkit-scrollbar {
    background-color: #212529;
    border-radius: 4px;
    height: 6px;
}

.list__video_livesteam_object::-webkit-scrollbar-track {
    background: #d3d3d3;
    border-radius: 4px;
}

.list__video_livesteam_object::-webkit-scrollbar-thumb {
    background-color: #212529;
    border: 1px solid transparent;
    /* background-clip: padding-box; */
    border-radius: 4px;
}

.list__video_livesteam_object::-webkit-scrollbar-thumb:hover{
    background-color: #212529;
}

/*  */
.item__video_livesteam {
    display: inline-block;
    width: 15%;
    margin: 0 2% 20px 0;
    position: relative;
}

.item__video_livesteam2 {
    display: inline-block;
    width: 15%;
    margin: 0 2% 20px 0;
    position: relative;
}

.video_livesteam {
    position: relative;
}

.video_livesteam time {
    position: absolute;
    bottom: 4%;
    right: 5%;
    font-size: 10.864px;
    line-height: 15px;
    text-align: right;
    color: #FFFFFF;
    background: #000000;
    opacity: 0.7;
    padding: 5px 9px;
    border-radius: 12px;
}

.video_livesteam img {
    width: 100%;
    height: 350px;
    object-fit: cover;
}

.item__video_livesteam2 .live,
.item__video_livesteam .live {
    position: absolute;
    top: 2%;
    right: 6%;
    background: #D0021B;
    font-size: 10px;
    line-height: 17px;
    color: #FFFFFF;
    padding: 0px 5px;
    border-radius: 2px;
}


.mg__button_1 {
    margin-bottom: 2rem;
}

/* video livesteam schedule */
.list__video_livesteam_schedule_object {
    white-space: nowrap;
    width: 100%;
    display: block;
    position: relative;
    scrollbar-width: thin;
    scrollbar-color: #212529 #d3d3d3;
}
/* scroll */
.list__video_livesteam_schedule_object::-webkit-scrollbar {
    background-color: #212529;
    border-radius: 4px;
    height: 6px;
}

.list__video_livesteam_schedule_object::-webkit-scrollbar-track {
    background: #d3d3d3;
    border-radius: 4px;
}

.list__video_livesteam_schedule_object::-webkit-scrollbar-thumb {
    background-color: #212529;
    /* background-clip: padding-box; */
    border: 1px solid transparent;
    border-radius: 4px;
}

.list__video_livesteam_schedule_object::-webkit-scrollbar-thumb:hover{
    background-color: #212529;
}

/*  */
.item__video_livesteam_schedule {
    display: inline-block;
    width: 15%;
    margin: 0 2% 20px 0;
    position: relative;
}

.item__video_livesteam_schedule2 {
    display: inline-block;
    width: 15%;
    margin: 0 2% 20px 0;
    position: relative;
}

.item__video_livesteam_mgr {
    margin-right: 0;
}

/* Button load more */
.list__video_livesteam_object_loadMore {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
}

.btn__loadMore {
    background: #FFF765;
    top: 0;
    right: 0;
    font-size: 12px;
    border-radius: 0;
    padding: 5px 32px;
    font-weight: bold;
}

.loadMore_padding_bottom {
    padding-bottom: 2rem;
}

.spinner-margin-right-text {
    margin-right: 0.35rem;
}

.text_video_hidden {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

@media (max-width: 812px) {

    .item__video_livesteam,
    .item__video_livesteam2 {
        display: inline-block;
        width: 23.5%;
        margin: 0 2% 20px 0;
        position: relative;
    }

    .item__video_livesteam_schedule,
    .item__video_livesteam_schedule2 {
        display: inline-block;
        width: 23.5%;
        margin: 0 2% 20px 0;
        position: relative;
    }

}

@media (max-width: 480px) {

    .item__video_livesteam,
    .item__video_livesteam2 {
        display: inline-block;
        width: 65.5%;
        margin: 0 2% 20px 0;
        position: relative;
    }

    .list__video_livesteam_object {
        height: 400px;
    }

    .item__video_livesteam_schedule,
    .item__video_livesteam_schedule2 {
        display: inline-block;
        width: 65.5%;
        margin: 0 2% 20px 0;
        position: relative;
    }

    .image_schedule_livestream img {
        height: 100%;
    }

    h5.video__title.cursor_pointer {
        display: none;
    }

    .meta__video.d-flex.justify-content-between span {
        display: none;
    }

    .list__video_livesteam_schedule_object {
        height: 380px;
    }
    .video_title_cus {
        display: block !important;
        width: 100%;
        text-overflow: ellipsis;
        height: 20px;
        white-space: nowrap;
    }
}