.box-not-event {
    display: flex;
    align-items: center;
    height: 200px;
    font-size: 2rem;
    text-align: center;
    color: #ffffff;
    opacity: 0.7;
}
.event-not-found {
    width: 100%;
}
.give_away_ticket__content.mt-2.view__room_f {
    font-size: 16px;
}
.give_away_ticket__content.mt-2.font__size_community {
    font-size: 14px;
}
.modal-content.modal-content__community.modal__free_ticket {
    width: 100%;
}
.content__free_ticket {
    font-size: 14px;
    font-weight: normal;
}
.pre__free_ticket {
    font-size: 100%;
    color: #fff;
    margin: 0;
    font-family: 'Noto Sans JP', sans-serif;
}
.expire_community__free_ticket_title, .expire_community_tickets, .expire_community_tickets {
    font-size: 14px;
    font-weight: normal;
    color: #fff;
}
.font__size_expire_community {
    font-size: 14px;
}
.expire_community__free_ticket_title {
    padding-bottom: 5px;
}
.expire_community_tickets {
    margin-left: 11px;
}
.expire_community_tickets_2 {
    margin-left: 25px;
}
.view__room_btn {
    background-color: #FFF765;
}

.modal-body.modal-body__free_ticket {
    padding: 10px;
}

.join_community__btn {
    padding: 0.4rem 0.5rem !important;
}

@media (max-width: 480px) {
    .mt-2.font__size_community {
        font-size: 12px;
    }
    .give_away_ticket__content {
        font-size: 14px;
    }
    .content__free_ticket {
        font-size: 11px;
    }
    .join_community__btn {
        width: 155px !important;
    }
    .expire_community__free_ticket_title, .expire_community_tickets, .expire_community_tickets, .font__size_expire_community {
        font-size: 11px;
    }
}
@media (max-width: 384px) {
    .content__free_ticket {
        font-size: 10px;
    }
    .expire_community__free_ticket_title, .expire_community_tickets, .expire_community_tickets, .font__size_expire_community {
        font-size: 10px;
    }
}
@media (max-width: 320px) {
    .give_away_ticket__content {
        font-size: 13px;
    }
    .content__free_ticket {
        font-size: 8px;
    }
    .join_community__btn {
        width: 133px !important;
    }
    .expire_community__free_ticket_title, .expire_community_tickets, .expire_community_tickets, .font__size_expire_community {
        font-size: 8px;
    }
}
