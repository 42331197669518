.container_ticket {
    margin: 0 auto;
    width: 65%;
}

/* .banner-home {
    background: url("/assets/images/background_ticket.png") center / cover
} */

.text-white {
    color: #fff!important;
    font-size: 14px;
}
.btn-default-ticket {
    color: #f7ed78;
    border: 1px solid #f7ed78;
    background: transparent;
    border-radius: .25rem;
    font-size: 14px;
}
.btn-default-ticket img {
    padding-right: 7px;
    padding-bottom: 3px;
}
.btn-default-ticket:hover {
    color: #f7ed78;
}
.btn-outline-light-event {
    color: #0a0a06;
    border: 1px solid #fff67a;
    border-radius: .25rem;
    background: #fff67a;
    font-size: 14px;
}

.typcn:before {
    font-family: typicons;
    font-style: normal;
    font-weight: 400;
    /* speak: none; */
    display: inline-block;
    text-decoration: inherit;
    width: 1.2em;
    font-size: 1.2em;
    text-align: center;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}

.typcn-ticket:before {
    content: "";
}
.typcn-flash-outline:before {
    content: "";
}

/* search */
.container__search {
    flex-direction: column;
    padding-bottom: 15px;
    position: relative;
}

.input_container {
    background: rgba(0,0,0,.6);
    width: 90%;
    height: 40px;
    min-width: 0;
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 4px;
    margin: 0 auto;
}
.input_container:focus-within::after {
    transform: scaleX(1);
}

.input__search {
    width: 94%;
    margin-left: 10px;
    min-width: 0;
    border: none;
    outline: none;
    height: 30px;
    color: #ffffff;
    font-size: 14px;
    letter-spacing: -0.7px;
    background: transparent;
    -webkit-tap-highlight-color: transparent;
}

.input__search::placeholder {
    color: #b3b3b3;
}

.dropdown__search {
    width: 90%;
    left: 5%;
    min-width: 0;
    max-height: 360px;
    overflow-x: hidden;
    border: 1px solid #b3b3b3;
    background-color: #D8D8D8;
    visibility: hidden;
    z-index: 10;
}
.dropdown__search.v {
    visibility: visible;
}

li.dropdown_item.cl__no_result {
    color: #000;
    opacity: 0.8;
}

.dropdown_position {
    position: absolute;
    top: 42px;
}

.dropdown_item {
    width: 100%;
    padding: 12px;
    cursor: pointer;
    border-bottom: 1px solid #b3b3b36b;
}
.dropdown_item:hover {
    background-color: #f1f1f1d7;
}

.item_text1 {
    color: #090a3c;
    margin-bottom: 5px;
}

.item_text2 {
    font-size: 14px;
    color: #090a3c80;
}
.btn__ticket_pb {
    padding-bottom: .8rem;
    width: 90%;
    margin: 0 auto;
}

/* custom */
.dispay__dropdown {
    display: flex;
    flex-direction: row;
}
.dispay__dropdown_image {
    width: 45px;
    height: 45px;
    margin-right: .8rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.dispay__dropdown_image img {
    width: 100%;
    height: 100%;
}

.button__search i {
    font-size: 24px;
    color: #f7ee7c;
    top: 6px;
    left: 12px;
}

.dropdown__search__ul {
    margin: 0;
    padding: 0;
}
@media screen and (max-width:1024px) {
    .container_ticket {
        width: 94%;
    }
    .container_event {
        width: 94% !important;
    }
}
@media (max-width: 768px) {
    .input_container {
      width: 97%;
    }
    .dropdown {
      width: 92%;
    }
    .input__search {
      width: 92%;
    }
    .button__search {
      width: 7%;
    }
    .container_ticket {
        margin: 0 auto;
        width: 91%;
    }
    .container_event {
        width: 91% !important;
    }
    .dropdown__search {
        width: 97%;
        left: 1.5%;
    }
}
@media (max-width: 414px) {
    .input__search {
        width: 90%;
        font-size: 12px;
    }
    .button__search {
        width: 13%;
    }
    .dropdown__search {
        width: 97%;
    }
}
@media (max-width: 375px) {
    .input_container {
      width: 90%;
      margin: 0 auto;
    }
    .dropdown {
      width: 90%;
    }
    .input__search {
      width: 81%;
      font-size: 12px;
    }
    .button__search {
      width: 15%;
    }
    .dropdown__search {
        width: 90%;
        left: 17px;
    }
}

@media (max-width: 320px) {
    .btn-default-ticket, .btn-outline-light-event {
        font-size: 11px;
    }
    .dropdown__search {
        width: 90.3%;
        left: 15px;
    }
}
