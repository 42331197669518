.animate__clap_live_stream, .animate__clap_live_stream1 {
    position: absolute;
    left: 18%;
    width: 25%;
    top: 10%;
}
.animate__clap_live_stream img, .animate__clap_live_stream1 img {
    width: 100%;
    border-radius: 30%;
}
.animate__tequila, .animate__tequila1 {
    position: absolute;
    left: 24%;
    width: 25%;
    top: 27%;
}
.animate__tequila img, .animate__tequila1 img {
    width: 100%;
    border-radius: 30%;
}
.animate__money_gun, .animate__money_gun1 {
    position: absolute;
    left: 30%;
    width: 25%;
    top: 20%;
}
.animate__money_gun img, .animate__money_gun1 img {
    width: 100%;
    border-radius: 30%;
}

/* @media screen and (max-width:992px) {
    .animate__clap_live_stream {
        left: 28%;
        width: 32%;
        top: -89%;
    }

    .animate__clap_live_stream1 {
        left: 30%;
        width: 32%;
        top: 2.7%;
    }

    .animate__tequila {
        left: 35%;
        width: 32%;
        top: -81%;
    }
    .animate__tequila1 {
        left: 30%;
        width: 32%;
        top: 3.4%;
    }
    .animate__money_gun {
        left: 35%;
        width: 32%;
        top: -83%;
    }
    .animate__money_gun1 {
        left: 30%;
        width: 32%;
        top: 4%;
    }
} */
@media screen and (max-width:768px) {
    .animate__clap_live_stream {
        left: 28%;
        width: 32%;
        top: -108%;
    }

    .animate__clap_live_stream1 {
        left: 30%;
        width: 32%;
        top: 2.7%;
    }

    .animate__tequila {
        left: 35%;
        width: 32%;
        top: -106%;
    }
    .animate__tequila1 {
        left: 30%;
        width: 32%;
        top: 3.4%;
    }
    .animate__money_gun {
        left: 35%;
        width: 32%;
        top: -105%;
    }
    .animate__money_gun1 {
        left: 30%;
        width: 32%;
        top: 4%;
    }
}

@media screen and (max-width: 480px) {
    .animate__clap_live_stream {
        top: -88%;
    }
    .animate__tequila {
        top: -87%;
    }
    .animate__money_gun {
        top: -89%;
    }
}

@media screen and (max-width: 375px) {
    .animate__clap_live_stream {
        top: -97%;
    }
    .animate__tequila {
        top: -94%;
    }
    .animate__money_gun {
        top: -96%;
    }
}
@media screen and (max-width: 320px) {
    .animate__clap_live_stream {
        top: -105%;
    }
    .animate__tequila {
        top: -104%;
    }
    .animate__money_gun {
        top: -106%;
    }
}
