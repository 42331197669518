/* Button create delivery schedule */
.create_delivery_schedule {
    display: block;
    font-size: 16px;
    width: 65%;
    padding-top: 4rem;
    text-align: end;
    margin: 0 auto;
}
.schedule-livestream-head {
    text-align: center;
    padding-top: 30px;
    color: #ffffff;
}
/*  */
.margin__bottom_1 {
    padding-bottom: 1rem;
}
.margin__bottom_5 {
    padding-bottom: 5rem;
}

/*  */
.schedule_content_day {
    display: flex;
    width: 70%;
}
.schedule_content_daytime {
    line-height: 2.5rem;
    width: 83%;
}
.btn_detail {
    width: 15%;
    text-align: center;
    margin: auto 0;
}
.btn-create-livestream {
    font-size: 14px!important;
    font-weight: bold!important;
    padding: 10px 20px!important;
    margin-right: 15px!important;
    width: 230px !important;
}
.schedule__but_detail {
    padding: 0.4rem 1.6rem;
    font-size: 12px;
    font-weight: bold;
    background-color: #FFF765;
}
a.img_start.img_start_length {
    font-size: 18px;
}

@media (max-width: 1024px) {
    .create_delivery_schedule {
        width: 94%;
    }
    .schedule_content_daytime {
        width: 78%;
    }
    .btn_detail {
        width: 20%;
    }
    .schedule__but_detail {
        padding: 0.4rem 1.4rem;
    }
}
@media (max-width: 768px) {
    .create_delivery_schedule {
        width: 91%;
    }
}
@media (max-width: 414px) {
    .create_delivery_schedule {
        /* width: 95%; */
        padding-top: 1rem;
    }
    .schedule_content_day {
        width: 100%;
        padding-left: 0.5rem;
    }
    .schedule__but_detail {
        padding: 0.3rem 1rem;
        font-size: 11px;
        font-weight: bold;
    }
    .best_event_title {
        font-size: 0.8rem;
        padding: 7px 15px;
    }
    .btn-create-livestream {
        margin-right: 0!important;
        font-size: 12px!important;
        padding: 7px 15px!important;
        width: 192px !important;
    }
    .schedule-livestream-head h2 {
        font-size: 20px;
    }
}
@media (max-width: 320px) {
    .schedule__but_detail {
        padding: 5px 8px;
    }
}
