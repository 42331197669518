@media screen and (max-width: 480px) {
    .box__livestram_pad {
        margin-right: -15px;
        margin-left: -15px;
    }
    .unset_box {
        min-height: 188px !important;
    }
}

@media screen and (max-width: 375px) {
    .unset_box {
        min-height: 184px !important;
    }
}

@media screen and (max-width: 360px) {
    .unset_box {
        min-height: 176px !important;
    }
}

@media screen and (max-width: 320px) {
    .unset_box {
        min-height: 157px !important;
    }
}