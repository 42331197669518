@import url('https://fonts.googleapis.com/css?family=Lato');

h5 {
	letter-spacing: 2px;
	text-align: center;
	text-transform: uppercase;
	color: #ffffff;
}

.countdown-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
}

.countdown-item {
	color: #FFF765;
	font-size: 26px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
    line-height: 30px;
	margin: 10px;
    padding-top: 10px;
	position: relative;
	width: 100px;
	height: 100px;
    border: 4px solid #fff;
    border-radius: 50%;
}

.countdown-item span {
	color: #FFF765;
	font-size: 12px;
	font-weight: 600;
	text-transform: uppercase;
}

.countdown-svg {
	position: absolute;
	top: -4px;
	left: -4px;
	width: 100px;
	height: 100px;
	color: #FFF765;

}

@media screen and (max-width: 480px) {
    .countdown-item {
        font-size: 18px;
        width: 66px;
        height: 66px;
        padding-top: 3px;
        margin: 2px;
		line-height: 22px;
    }
    .countdown-svg {
        width: 66px;
        height: 66px;
    }
}

