.box__video_detail {
  width: 100%;
}

.row-back-my-page {
  margin-bottom: 3rem;
}

@media screen and (max-width: 992px) {
  .box__video_detail {
    min-height: 682px !important;
  }
}

@media screen and (max-width: 768px) {
  .box__video_detail {
    min-height: unset !important;
  }
}

@media screen and (max-width: 480px) {
  .box__video_detail {
    width: auto;
  }
  .row-back-my-page {
    margin-bottom: 1rem;
  }
}
