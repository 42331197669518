.intro .site--title {
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 400;
}
.about_cus {
    padding-top: 2rem;
    padding-bottom: 2.5rem;
    background-color:#464646;
    min-height: 70vh;
}
.about_cus .cm-movies {
    margin-top: 0;
    width: 100%;
}
.video__custom {
    width: 68%;
}
section.section.section__live_stream {
    padding-top: 3rem;
    background-color: #464646;
    padding-bottom: 1rem;
}
i.icon.fa.fa-music {
    font-size: 6rem;
}

.intro .background-movie {
    position: unset;
    z-index: 0;
}

.home_page__ticket, .home_page__event_category {
    background-color:#464646;
    margin-top: -1px;
}

.hidden__page {
    display: none;
}
.container-fluid__livesteam {
    width: 88%;
    margin: 0 auto;
}
.nav-item_cus {
    width: 120px;
}
.not-absolute-announcement {
    background-color: unset;
    z-index: 0;
}

@media (max-width: 1024px) {
    .container-fluid__livesteam {
        width: 94%;
    }
}
@media screen and (min-width:1361px) and (max-width:1560px) {
    .about_cus {
        min-height: 59vh;
        margin-top: -1px;
    }
}
@media screen and (min-width:1025px) and (max-width:1360px) {
    .video__custom {
        width: 88%;
    }
}
@media screen and (min-width:992px) and (max-width:1024px) {
    .video__custom {
        width: 94%;
    }
    .about_cus {
        padding-bottom: 2rem;
        min-height: 48vh;
    }
}
@media screen and (min-width:768px) and (max-width:992px) {
    .video__custom {
        width: 100%;
    }
    .about_cus {
        min-height: 39vh;
    }
    .about_cus .cm-movies {
        width: 94%;
        margin: 0 auto;
    }
    .intro .site--title {
        font-size: 3rem;
    }
}
@media (max-width: 768px) {
    .container-fluid__livesteam {
        width: 91%;
    }
    body .container {
        width: 95%;
    }
    .about_cus {
        height: auto;
        padding: 1.5rem 0;
        margin-top: -1px;
        margin-bottom: -4px;
    }
    .about_cus .cm-movies {
        width: 91%;
        margin: 0 auto;
    }
    section.section.section__live_stream {
        margin-top: 0rem;
        padding-top: 1rem;
    }
    .intro .site--logo {
        margin-top: 35px;
    }
    .section_livestream_info {
        padding: 0 0 0.5rem !important;
        margin: 0.5rem 0 !important;
    }
    .heading_livedream {
        font-size: 16px !important;
    }
    .section_livestream_info {
        font-size: 14px;
    }

    .tab__box_chat {
        padding: .2rem 1rem;
    }
}
@media (max-width: 480px) {
    section.section.section__live_stream {
        padding-top: 0;
    }
    section.section.section__live_stream.section__box_live_stream {
        padding-top: 20px;
    }
    .about_cus {
        min-height: 265px;
        padding: 0;
        padding-top: 1rem;
    }
    .intro {
        height: 50vh;
    }
}
@media (max-width: 414px) {
    body .container {
        width: 100%;
    }
}

@media (max-width: 375px) {
    .about_cus {
        min-height: 245px;
    }
}
@media (max-width: 320px) {
    .about_cus {
        min-height: 215px;
    }
    .heading_livedream {
        font-size: 14px !important;
    }
}