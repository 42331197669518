.invalid-feedback {
  display: block;
  text-align: right;
}
.input__password {
  flex-flow: column;
  flex: 0 0 100%!important;
}
.input__password input {
  width: 100%!important;
}

.resend-button {
  cursor: pointer;
  color: #FFF765!important
}
.is-invalid {
  border: 1px solid red;
}
