/* button */
.btn_user__custom {
    padding: 0.4rem 1.6rem;
    margin-bottom: 2px;
}

/* padding */
.padding__top_3 {
    padding-top: 3rem;
    margin-top: 3rem;
}

button.btn.btn_user_profile.btn_user__custom.active_follow {
    background-color: #ffff00;
    border: 1px solid #ffff00;
    color: #000000;
}