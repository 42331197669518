@media (max-width: 768px) {
  .home__page_video_detail {
    min-height: calc(100vh - 332px) !important;
  }
}

@media (max-width: 480px) {
  .home__page_video_detail {
    min-height: calc(100vh - 450px) !important;
  }
}

