/* event-desc */
.event-desc {
    margin-top: 1.5rem;
    text-align: justify;
    word-break: break-all;
    white-space: pre-wrap;
}

/* buy ticket */
.apply_purchase__ticket {
    display: flex;
    border-bottom: 2px solid #a4a4a4;
}
.apply_purchase_content {
    width: 80%;
    padding: 0.5rem 0;
    font-size: 14px;
}
#id {
    width: 80%!important;
}
span.content_1, span.content_2  {
    font-size: 13px;
    display: flex;
    padding: 0.1rem 0;
    word-break: break-all;
}
.content_3__dp {
    display: flex;
}
.apply_purchase_buy_ticket {
    width: 25%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.apply_purchase_btn {
    background-color: #FFF765;
    border-radius: 10px;
    font-size: 11px;
    font-weight: bold;
    padding: 0.67rem;
}


hr.event__hr {
    border: 1px solid #000000;
}
/* btn back */
.event__back{
    text-align: center;
}
button.btn.btn__back {
    color: #FFF765;
    display: inline-flex;
    align-items: center;
}
.btn__back i{
    margin-right: 0.7rem;
}

.ticket_img_live {
    width: 74px;
    height: 24px !important;
    margin-top: -5px;
}
.click_view_page {
    padding-top: 0.5rem;
    font-size: 14px;
    text-align: end;
}
.click_view_page_a {
    color: #4a76c6;
    text-decoration: underline #4a76c6;
}

