@media (min-width:1200px) {
    /* .container-fluid { max-width: 1440px; } */
    .container, .container-lg, .container-md, .container-sm, .container-xl { max-width: 1330px; }
}
@media screen and (min-width:992px) and (max-width:1360px) {
    .type__comment input { width: 100%; }
    .container, .container-lg, .container-md, .container-sm { max-width: 100%; }
    .list__video_related .item__video:nth-child(6n) { margin-right: 0; }
    .list__video_related .item__video { 
        margin-bottom: 1.5rem; 
        margin-right: 1%; 
        width: 16%;
    }
    .section_livestream_info .stream__info {
        width: 82%;
    }
}
@media screen and (max-width:992px) {
    .container, .container-lg, .container-md, .container-sm { max-width: 100%; }

    /* Menu */
        .ic__coin_ld, .navbar-brand { display: none !important; }
        button.navbar-toggler i { color: #fff; font-size: 20px;}
        div#menu { position: relative; }
        div#navbarText { position: absolute; background: #000; width: 100%; top: 31px; }
        .menu__center li {padding: 1rem;text-align: left;}
        .div_mobile{display: block;}
        #menu_user_dropdow {
            flex: 0 0 18%;
            text-align: right;
        }
        .nav-link__login {
            justify-content: flex-end;
        }
    /* End Menu */

    /* Register */
        .form_register { width: 90%; }
        .list_progress_login { width: 100%; }
        .progress__line { padding-left: 0rem; padding-right: 0rem; }
        .progress__text { text-align: left; letter-spacing: 1px; }
        .user__actions .dropdown-menu .dropdown-item { padding: .5rem 0.5rem; }
    /* End Register */

    /* Home */
    .home__page .home__image { position: absolute; width: 100%; height: 100%; object-fit: cover; }
    .list__livestream_login { width: 100%; height: 100%; min-height: calc(100vh - 47px); position: relative; z-index: 1; top: 0; padding: 4rem 0; }
    #tabsCoins.popup__show { padding-bottom: 1rem; }
    #hideBoxChat span { margin-right: 0.5rem; }
    #hideBoxChat { display: flex; width: 100%; justify-content: center; align-items: center; color: #fff; background: #000; font-size: 12px; }
    .border_show_chat {  border-top-left-radius: 0; border-top-right-radius: 0; }
    .absolute-announcement .container {
        margin: 22vh auto;
    }
    .section_livestream_info .stream__info {
        width: 77%;
    }
    .list__video_related .item__video {
        margin-right: 0.5%;
        width: 17%;
    }
}
@media screen and (max-width:768px) {
    .box__live_streams { display: block !important; }
    .btn__double_right { display: none !important}
    html.homepage .menu__center li { padding: 0.6rem; }
    .embed__source iframe { height: 320px; }
    .list__video_related .item__video { flex: 0 0 32%; margin-bottom: 1.5rem; margin-right: 2%; }

    .show_par_box_chat {
        height: 451px;
    }
    #menu_user_dropdow {
        width: 23%;
        flex: unset;
        margin-right: 20px;
    }
    .user__actions_custom {
        display: inline-flex;
    }
    #tabsCoins.popup__show {
        min-height: unset;
        /* height: calc(100vh - 643px); */
        height: 379px;
        margin-top: 5px;
        padding-bottom: 0;
    }
    .content__box_chat {
        padding: 0.5rem;
        height: 382px;
        /* height: calc(100vh - 639px); */
    }

    .type__comment {
        width: 90%;
    }
    .box_type_chat button.show_emoji {
        margin-left: -3.3rem;
    }
    .box_type_chat button {
        width: 40px;
        height: 40px;
    }
    .list_chat .user_info {
        margin-top: -2px;
    }
    .section_livestream_info .acction_share span i {
        font-size: 14px;
    }
    #tabsCoins.popup__show ul li button img {
        width: 85%;
    }
    .section_livestream_info .acction_share {
        width: 36%;
    }
    .stream__info {
        width: 125% !important;
    }
}
@media screen and (max-width:480px) {
    .button {
        width: 140px;
    }
    html.homepage .menu__center li { padding: 0.2rem; }
    #tabsCoins.popup__show {
        min-height: unset;
        /* height: calc(100vh - 455px); */
        height: 250.5px;
        margin-top: 5px;
        padding-bottom: 0;
    }
    #tabsCoins.popup__show ul.add_new_coins {
        margin-top: 1rem;
    }
    #tabsCoins.popup__show ul li button {
        padding: 0 0.5rem;
        min-height: 2.5rem;
    }

    .content__box_chat {
        padding: 0.5rem;
        height: 254px;
        /* height: calc(100vh - 451px); */
    }
    .section_livestream_info .acction_share span i {
        font-size: 12px;
    }
    .show_par_box_chat {
        height: 325px;
    }
    img.user__avatar {
        width: 30px !important;
        height: 30px !important;
    }
    .list_chat .user_info {
        margin-top: -10px;
    }
    .list_chat .user_info .name {
        font-size: 10px;
        line-height: 10px;
    }
    .stream_description .author__name {
        line-height: 14px;
    }

    .popup__show ul li { flex: 0 0 33.3%; }
    .form_register .locate__input .input__verify_code input { width: 50px; }
    .progress__line .line { width: 30%; }
    .form_register .locate__input input { width: 100%; }
    .login__form { width: 90%; }
    .list__video_related .item__video:nth-child(3n) { margin-right: 0; }
    .list__video_related .item__video { flex: 0 0 50%; margin-bottom: 1.5rem; margin-right: 0;}
    .box_type_chat button {
        margin-left: 0.5rem;
        width: 32px;
        height: 32px;
    }
    .box_type_chat button.show_emoji {
        margin-left: -2.45rem;
    }
    .yellow__button i { font-size: 1rem; }
    .break__scrum a { font-size: 12px; }
    .break__scrum i.fa-home { font-size: 17px; }
    .break__scrum .fa-chevron-right { font-size: 10px; }
    .open_app_link span { display: none; }
    .item__video time, .item__video .video__title, .item__video .meta__video { display: none !important; }
    .mobile_video_info {
        display: flex !important;
        border-radius: 0rem;
        justify-content: left !important;
        flex-wrap: wrap;
        flex-direction: column-reverse;
        text-align: left;
        align-items: flex-start !important;
        font-size: 12px;
        width: 100.04%;
    }
    .video_title{
        margin-bottom: 5px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .section_livestream_info {
        display: block !important;
        padding: 0 0 0.5rem;
        margin: 0.5rem 0;
    }
    .section_livestream_info .acction_share span { padding-left: 0; padding-right: 0.5rem;}
    .section_livestream_info .meta_info, .section_livestream_info .acction_share {
        /* margin-top: 0.3rem; */
        font-size: 12px;
        width: 100%;
    }
    .section_livestream_info .stream__info .heading_livedream {
        font-size: 14px !important;
        line-height: 17px;
        padding-bottom: 5px;
    }
    .stream_description {
        margin-bottom: 6px;
    }
    button#sentMess {
        width: 0 !important;
    }
    .type__comment {
        padding: 3px;
        width: 74%;
    }
    .type__comment i {
        margin-right: -40px;
        font-size: 19px;
    }
    .mobile_video_info span{padding-left: 5px; font-size: 12px;}

    #menu_user_dropdow {
        flex: unset;
    }
    #menu_user_dropdow {
        width: 35%;
        margin-right: 20px;
    }
    .user__actions_custom {
        display: inline-flex;
    }
    .nav-link__login {
        padding: 0 12px 0 0;
    }
    .mobile_video_info .avatar {
        display: inline-block;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .mobile_video_info .avatar img{
        width: 20px;
        height: 20px;
    }
    .stream_description .author__name h6 {
        font-size: 12px;
    }
    span.menu__login {
        font-size: 13px;
    }
    .absolute-announcement .container {
        margin: 27vh auto;
    }
    .absolute-announcement .container .close-announcement {
        top: 120px;
    }
    .absolute-announcement p {
        margin-top: 7rem;
    }
    .absolute-announcement p, .absolute-announcement a {
        font-size: 1.2rem;
    }
    .absolute-announcement p i {
        font-size: 37px;
    }
    #tab__list_livestream.nav-tabs .nav-link {
        padding: .35rem 1rem;
    }
    .navbar {
        padding: 0;
    }
    .menu__logo.mr-auto {
        padding-left: 4%;
    }
    .stream__info {
        width: 100% !important;
    }
    .author_detail {
        line-height: 14px;
    }
    .video__related {
        padding-top: 10px;
    }
}
@media screen and (max-width:411px) {
    .show_par_box_chat {
        height: 299px;
    }
    .content__box_chat {
        height: 229px;
    }
    #tabsCoins.popup__show {
        height: 225.2px;
    }
}
@media screen and (max-width:384px) {
    .show_par_box_chat {
        height: 268px;
    }
    .content__box_chat {
        height: 198px;
    }
    #tabsCoins.popup__show {
        height: 194.2px;
    }
    #tabsCoins.popup__show ul li button {
        min-height: 2rem;
    }
    #tabsCoins.popup__show ul li button img {
        width: 57%;
    }
    .popup__show ul li span {
        margin-top: 0.3rem;
    }
}
@media screen and (max-width:375px) {
    .show_par_box_chat {
        height: 280px;
    }
    .content__box_chat {
        padding: 0.5rem;
        height: 210px;
        /* height: calc(100vh - 440px); */
    }
    #tabsCoins.popup__show {
        /* height: calc(100vh - 446px); */
        height: 204.2px;
    }
    #menu_user_dropdow {
        width: 37%;
        margin-right: 24px;
    }
    #tabsCoins.popup__show ul li button {
        min-height: 2.7rem;
    }
    .popup__show ul li span {
        margin-top: 0.1rem;
    }
    .stream_description .btn__follow_chanel {
        padding: 4px 8px;
        font-size: 11px;
    }
    .tab__box_chat img {
        width: 20px;
    }
    .type__comment {
        width: 70%;
    }
    .box_type_chat button.show_emoji {
        margin-left: -2.5rem;
    }
    .type__comment form {
        height: 1.4rem !important;
    }
    .type__comment img {
        height: 1.2rem;
    }
    .stream_description .author__name {
        line-height: 12px;
    }
    span.menu__login {
        font-size: 12px;
    }
    .iteam__schedule_livestream .live.notification {
        font-size: 12px;
    }
}
@media screen and (max-width:360px) {
    .show_par_box_chat {
        height: 274px;
    }
    .content__box_chat {
        height: 206px;
    }
    #tabsCoins.popup__show {
        height: 200.2px;
    }
}
@media screen and (max-width:320px) {
    .absolute-announcement .container {
        margin: 20vh auto;
    }
    .absolute-announcement .container .close-announcement {
        top: 95px;
    }
    .absolute-announcement a, .absolute-announcement p {
        font-size: 1rem;
    }
    .tab__box_chat {
        padding: 0.1rem 1rem;
    }
    .tab__box_chat img, #convertionTabs.nav-tabs .nav-link .donate-img {
        min-height: 17px;
    }
    .content__box_chat {
        /* height: calc(100vh - 383px); */
        height: 160px;
    }

    #tabsCoins.popup__show {
        /* height: calc(100vh - 387px); */
        height: 154.5px;
    }
    .type__comment {
        width: 68%;
    }
    .type__comment i {
        font-size: 16px;
        margin-right: -33px;
    }
    .type__comment img {
        height: 1rem;
    }
    .box_type_chat button {
        width: 28px;
        height: 28px;
    }

    #tabsCoins.popup__show ul {
        justify-content: flex-start;
        width: 100%;
        margin: 0 4%;
    }
    #tabsCoins.popup__show ul li {
        flex: 0 0 30%;
    }
    #tabsCoins.popup__show ul li button {
        min-height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    #tabsCoins.popup__show ul li button img {
        width: 41%;
    }
    .btn__buy_coin {
        width: 20px !important;
    }
    .show_par_box_chat {
        height: 225px;
    }
    .section_livestream_info .meta_info span {
        font-size: 11px;
    }
    .section_livestream_info .meta_info, .section_livestream_info .acction_share {
        font-size: 11px;
    }
    .section_livestream_info .acction_share span i {
        font-size: 12px;
    }
    .user_infor img {
        width: 2rem;
        height: 2rem;
    }
    .stream_description .author__name {
        color: #fff;
        padding-left: 0.5rem;
        line-height: 11px;
    }

    .list__livestream_login ul li { min-width: unset; }
    .list__livestream_login ul { padding: 0 0.5rem; }
    .sub__content span { letter-spacing: unset; }
    #menu_user_dropdow {
        width: 47%;
        margin-right: 19px;
    }
    .stream_description .author__name h6 {
        font-size: 11px;
    }
    span.menu__login {
        font-size: 11px;
    }

    .iteam__schedule_livestream .live.notification {
        font-size: 9px;
    }
    .iteam__schedule_livestream .timeLivestream {
        font-size: 9px;
    }
}