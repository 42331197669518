.container_event {
    margin: 0 auto;
    width: 65%;
    padding-top: 1rem;
}

.event__category_title {
    color: #fff;
    font-size: 36px;
    font-weight: normal;
    text-align: center;
}

.event__category_note {
    color: #FFFFFF;
    /* font-weight: bold; */
    text-align: center;
    font-size: 14px;
    font-weight: normal;
}

.event__category_slide {
    height: 2.5rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn__event_prev {
    margin-left: -1rem;
}
.btn__event_next {
    margin-right: -1rem;
}

.hr__event {
    margin-top: 0;
    background: #979697;
}

.event__category_slide_span {
    width: 100%;
    margin-top: unset;
    margin-bottom: 0;
    padding-left: 0;
}

.event__category_slide_a {
    font-size: 16px;
    font-weight: lighter;
    width: 16.6%;
    color: #efefef;
}

.event__category_slide_a:hover {
    color: #efefef;
}

.btn.event__category_slide_a.active {
    color: #ffff00;
}

/* .btn.event__category_slide_a.active:hover {
    color: #efefef;
} */

.event__category_content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    /* overflow-y: overlay; */
}
.item__video.iteam__schedule_livestream.item__event__category{
    flex: 0 0 23% !important;
    margin: 0 1% 30px;
    margin-right: 1% !important;
    width: 23%;
}
h5.event__title {
    color: #fff;
    margin-top: 10px;
}
.event__category_load_more {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.btn_event_load_more {
    border-radius: 0;
    padding: 7px 0 7px 15px;
    color: #fff67a;
}
.btn_event_load_more:hover {
    color: #fff67a;
}

/*  */
.list__event_category_object {
    height: 715px;
    white-space: nowrap;
    width: 100%;
    display: block;
    position: relative;
}
.list__event_category_object {
    scrollbar-width: thin;
    scrollbar-color: #464646 #d3d3d3;
}

.list__event_category_object:-webkit-scrollbar {
    width: 11px;
}

.list__event_category_object:-webkit-scrollbar-track {
    background: #d3d3d3;
}

.list__event_category_object:-webkit-scrollbar-thumb {
    background-color: #464646;
    border-radius: 6px;
    border: 1px solid transparent;
}

/* webkit browsers */
.list__event_category_object::-webkit-scrollbar,
.list__event_category_object::-webkit-scrollbar-thumb {
    height: 6px;
    border-radius: 6px;
    background-clip: padding-box;
    border: 1px solid transparent;
}

.list__event_category_object::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 0 10px;
}
.item__event_category {
    display: inline-block;
    width: 23.5%;
    margin: 0 2% 20px 0;
    position: relative;
}

.list__event_category_related {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}
.list__event_category_related .item__video {
    position: relative;
}
.video__title_category {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #FFFFFF;
    margin: 0;
    overflow: hidden;
    margin-top: 0.5rem;
}
.text_event_hidden {
    width: 100%;
    /* display: -webkit-box; */
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: nowrap;
    text-overflow: ellipsis;
}

@media screen and (max-width:1360px) {
    .event__category_slide_a {
        font-size: 14px;
    }
}
@media (max-width: 1024px) {
    .list__event_category_related {
        padding-left: 12px;
    }
    .event__category_slide_a {
        width: 20%;
    }
    .event__category_slide_a {
        font-size: 11px;
    }
    .item__video.iteam__schedule_livestream.item__event__category {
        flex: 0;
        display: inline-block;
        /* width: 48.5%; */
        margin: 0 2% 30px 0;
        position: relative;
        flex: 0 0 24% !important;
    }
    .item__event_category {
        display: inline-block;
        width: 48.5%;
        margin: 0 2% 20px 0;
        position: relative;
    }
}

@media (max-width: 768px) {
    .list__event_category_related {
        padding-left: 0;
    }
    .event__category_title {
        font-size: 2rem;
    }
    .container_event {
        margin: 0 auto;
        width: 100%;
        background: #464646;
    }

    .event__category_content .item__event__category {
        flex: 0 0 48% !important;
    }
    .event__category_slide_a {
        font-size: 12px;
    }
    .item__event_category {
        display: inline-block;
        width: 23.5%;
        margin: 0 2% 20px 0;
        position: relative;
    }
    .item__video.iteam__schedule_livestream.item__event__category {
        flex: 0 0 24% !important;
        display: inline-block;
        width: 48.5%;
        margin: 0 1% 30px 0;
        position: relative;
    }
}

@media (max-width: 480px) {
    .event__category_title {
        font-size: 1.4rem;
    }
    .event__category_note {
        font-size: 0.8rem;
    }
    .event__category_slide_a {
        width: 50%;
        font-size: 12px;
    }
    .btn__event_prev {
        margin-left: 0;
    }
    .btn__event_next {
        margin-right: 0;
    }
    .item__video.iteam__schedule_livestream.item__event__category {
        flex: 0 0 49% !important;
        width: 49%;
        margin: 0 1% -23px 0;
    }
    .mobile__category_infor {
        bottom: 48.2px !important;
    }
    .list__event_category_object {
        height: 660px;
    }
    h5.video__title {
        display: none;
        overflow: hidden;
    }
    .text-white {
        padding-left: .5rem!important;
    }
    .event__category_content {
        padding: 0;
    }
    .video_event__category {
        margin-top: 0;
    }
}

@media (max-width: 411px) {
    .mobile__category_infor {
        bottom: 45.2px !important;
    }
}

@media (max-width: 384px) {
    .mobile__category_infor {
        bottom: 23.2px !important;
    }
}

@media (max-width: 375px) {
    .event__category_slide_a {
        font-size: 11px;
    }
    .list__video_livesteam_schedule_object {
        height: 340px;
    }
    .item__video.iteam__schedule_livestream.item__event__category {
        flex: 0 0 49% !important;
        width: 49%;
        margin: 0 1% 3px 0;
    }
    .mobile__category_infor {
        bottom: 15.2px !important;
    }
}
@media (max-width: 360px) {
    .mobile__category_infor {
        bottom: 3.2px !important;
    }
}
@media (max-width: 320px) {
    .event__category_slide_a {
        font-size: 10px;
    }
    .list__video_livesteam_schedule_object {
        height: 295px;
    }
    .item__video.iteam__schedule_livestream.item__event__category {
        flex: 0 0 49% !important;
        width: 49%;
        margin: 0 1% 50px 0;
    }
    .mobile__category_infor {
        bottom: -31px !important;
    }
}
