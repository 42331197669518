/* buy ticket */
.event-content .buy__ticket {
    background-color: #ffffff;
    color: #000;
    padding: 11px 30px;
    margin-top: 4.5rem;
    max-height: 384px;
    overflow: auto;
}
.apply_purchase {
    border-bottom: 2px solid #a4a4a4;
    padding: 0.5rem;
    text-align: center;
    font-weight: 600;
}
.apply_purchase__ticket {
    display: flex;
    border-bottom: 2px solid #a4a4a4;
}
.apply_purchase_content {
    width: 80%;
    padding: 0.5rem 0;
    font-size: 14px;
}
#id {
    width: 80%!important;
}
span.content_1 img, span.content_2 img, span.content_3 img {
    padding-right: 0.5rem;
    height: 1rem;
    position: relative;
    bottom: -2px;
}
.content_3 {
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    font-weight: normal;
    /* color: #4A4A4A; */
    padding: 0.1rem 0;
}
.content_3__dp {
    display: flex;
}
.end_of_sale {
    display: flex !important;
    flex-direction: column;
}
.apply_purchase_btn {
    background-color: #FFF765;
    border-radius: 10px;
    font-size: 11px;
    font-weight: bold;
    padding: 0.67rem;
}

.content_3_price {
    width: 25%;
}
.content_3_calendar {
    width: 47%;
}
.content_3_ticket {
    width: 28%;
}


hr.event__hr {
    border: 1px solid #000000;
}
/* btn back */
.event__back{
    text-align: center;
}
button.btn.btn__back {
    color: #FFF765;
    display: inline-flex;
    align-items: center;
}
.btn__back i{
    margin-right: 0.7rem;
}

.ticket_img_live {
    width: 74px;
    height: 24px !important;
}
.click_view_page {
    padding-top: 0.5rem;
    font-size: 14px;
    text-align: end;
}
.click_view_popup_a {
    padding-top: 0.5rem;
    font-size: 9px;
    text-align: end;
}
.click_view_page_a, .click_view_popup_a{
    color: #4a76c6;
    text-decoration: underline #4a76c6;
}


.w__apply_purchase_btn {
    width: 82%;
}
.not__cursor {
    cursor: unset !important;
}
@media screen and (min-width:992px) and (max-width:1360px) {
    .w__apply_purchase_btn {
        width: 100%;
    }
}
@media screen and (max-width:1024px) {
    .content_3 {
        flex-direction: column;
    }
    .content_3_price, .content_3_calendar, .content_3_ticket {
        width: 100%;
        padding: 0.1rem 0;
    }
}
@media (max-width: 812px) {
    .event-content .buy__ticket {
        margin-top: 0 !important;
    }
}
@media (max-width: 768px) {
    .content_3 {
        flex-direction: row;
    }
    .content_3_price {
        width: 24%;
    }
    .content_3_calendar {
        width: 47%;
    }
    .content_3_ticket {
        width: 28%;
    }
    .event-content .buy__ticket {
        margin-top: 1rem;
    }
}
@media (max-width: 480px) {
    .btn__flow, .btn__copy_url {
        font-size: 12px;
    }

    .event-content .buy__ticket {
        padding: 11px 10px!important;
        margin-bottom: -2rem;
        margin-top: -1rem !important;
    }
    .apply_purchase {
        font-size: 15px;
    }
    .apply_purchase_content {
        width: 100%!important;
    }
    .apply_purchase__ticket {
        flex-direction: column;
    }
    .apply_purchase_buy_ticket {
        width: 100%!important;
        margin-bottom: 10px;
    }
    .content_3 {
        flex-direction: column;
    }
    span.content_1 img, span.content_2 img, span.content_3 img {
        padding-right: 1rem;
    }
    .content_3 span {
        width: 100%!important;
    }
}
