/* account distribute */
.event__ticket_distribute {
    margin-bottom: 2rem;
}
.account_distribute {
    background-color: #C0C0C0;
    color: #000;
    padding: 0.5rem 1.5rem 1rem;
    margin-top: 1rem;
}

/* radio */
.buy__ticket__radio {
    width: 20%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.buy__ticket__radio input[type="radio"] {
    display: none;
}
.buy__ticket__radio label {
	position: relative;
    display: inline-block;
    padding: 8px 3px 3px 28px;
    cursor: pointer;
    white-space: pre;
}
.buy__ticket__radio label::before {
    position: absolute;
    content: '';
    top: 50%;
    left: 0;
    width: 22px;
    height: 22px;
    margin-top: -8px;
    background: #fff;
    border: 1px solid #FFF765;
    border-radius: 100%;
}
.buy__ticket__radio input[type="radio"]:checked + label::after {
	position: absolute;
    content: '';
    top: 50%;
    left: 4px;
    width: 16px;
    height: 16px;
    margin-top: -5px;
    margin-left: -1px;
    background: #FFF765;
    border-radius: 100%;
}

/* account distribute item */
.account_distribute_item {
    display: flex;
    align-items: center;
    margin: 1rem;
}
label.container-checkbox.account_distribute__label {
    justify-content: unset;
    align-items: unset;
}
.account_distribute__checkbox {
    margin-right: 2.5rem;
}
.account_distribute__img {
    width: 15%;
    margin-right: 2rem;
}
.account_distribute__img img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
}
.account_distribute__name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/*  */
.buy__ticket.buy__ticket_custom {
    margin-top: 1rem;
    max-height: 630px;
}
.apply_purchase__ticket_finish {
    display: flex;
}
.bd_line {
    border-bottom: 2px solid #a4a4a4;
}
.live_content_text {
    text-align: justify;
    padding: 20px 0;
}
.list_follower_scroll {
    height: 490px;
    overflow-y: scroll;
}
@media (max-width: 1024px) {
    .account_distribute__img {
        margin-right: 3rem;
    }
}
@media (max-width: 480px) {
    .title__create_schedule {
        font-size: 20px;
    }
    .account_distribute {
        padding: 8px 3px 16px;
        margin-top: 3rem;
    }
    .account_distribute__img {
        margin-right: 1rem;
    }
    .account_distribute__img img {
        width: 50px;
        height: 50px;
    }
}
@media (max-width: 320px) {
    .event__account_distribute {
        padding-right: 0;
        padding-left: 0;
    }
    .account_distribute_item {
        margin: 4px;
    }
    .account_distribute {
        padding: 8px 0 16px;
    }
    .account_distribute__checkbox {
        margin-right: 1.5rem;
    }
    .checkmark__delivered {
        height: 22px !important;
        margin-right: -17px !important;
        margin-left: -1rem !important;
        font-size: 11px !important;
    }
}
