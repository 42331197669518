.event-content-title {
    font-size: 35px;
    overflow-wrap: break-word;
}
.event-infor {
    display: flex;
}
.event-meta {
    text-decoration: none;
    list-style: none;
    padding-right: 2rem;
    width: 38%;
}
.resize-icon {
    width: 1rem;
}

.event-meta-date {
    padding-left: 0.5rem;
}
ul.event-meta li {
    padding-top: 0.7rem;
    display: flex;
    align-items: center;
}
ul.event-meta li:nth-child(2) {
    display: block;
}
.badge-custom {
    margin-left: 0 !important;
    margin-right: 5px;
    margin-top: 5px;
    font-weight: normal;
    font-size: 10px;
}

.event__infor_user {
    padding: 1rem;
    width: 62%;
    color: #ffffff;
}
.event__infor_user_img {
    display: flex;
    align-items: center;
}
span.event__img {
    width: 30%;
}
.event__infor_user_img img {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    border: 1px solid #fff;
}
.btn__flow, .btn__copy_url {
    border: 1px solid;
    color: #ffffff;
    margin: 0 0.5rem 0.2rem;
    border-radius: 10px;
}
.btn__copy_url {
    width: 125px;
}
.btn__flow:hover, .btn__copy_url:hover {
    border: 1px solid #ffff00;
    color: #e7e3e3;
}

.event__infor_user .author__name {
    margin-top: 1rem;
}
.author__name h6 {
    font-size: 16px;
}
.event_author_detail {
    font-size: 14px;
    font-weight: 500;
}
.event_follows {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    padding-top: 1rem;
}

button.btn.btn__flow.active_follow {
    background-color: #ffff00;
    border: 1px solid #ffff00;
    color: #000000;
}
.disable__follow {
    color: #ffffff;
    margin: 0 0.5rem 0.2rem;
    border-radius: 10px;
    padding-right: 125px;
}

.event__hr {
    width: 100%;
}

.display-xs {
    display: none;
}
/* .hide-xs {
    display: block;
} */
.hidden-username-xs {
    display: none;
}
@media screen and (max-width:1024px) {
    .hide-xs {
        display: block;
    }
    .event_pd_r {
        padding-right: 10px;
    }
}
@media screen and (max-width:768px) {
    .event-meta {
        width: 40%;
    }
}
@media screen and (max-width:480px) {
    .event-infor {
        flex-direction: column;
    }
    .event-meta {
        width: 100% !important;
    }
    .event__infor_user {
        padding: 0 !important;
        width: 100% !important;
    }
    span.event__img {
        width: 15%;
    }
    .event__img img {
        width: 45px;
        height: 45px;
    }
    .display-xs {
        display: block;
    }

    .hide-xs {
        display: none!important;
    }

    .btn__flow, .btn__copy_url {
        margin-left: 0;
        width: 45%;
    }
    .btn__copy_url {
        float: right;
        margin-right: 0;
    }

    .event_follows {
        font-size: 10px!important;
    }
    .event-desc {
        margin-top: 0!important;
    }
    .event-content-title {
        font-size: 18px !important;
        margin-bottom: 4px !important;
        word-break: break-all;
    }
}
@media screen and (max-width:320px) {
    .event-content-title {
        font-size: 20px !important;
    }
    .display-xs h6 {
        font-size: 14px;
        margin-left: 14px;
    }
    .btn__flow, .btn__copy_url {
        font-size: 10px !important;
        width: 48% !important;
    }
    .event_follows {
        font-size: 9px!important;
    }
}
