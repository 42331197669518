@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap");
.input-schedule:focus {
    color: #fff;
    background-color: transparent;
    border-color: #fff;
    outline: 0;
    box-shadow: none;
}
.input-schedule {
    background-color: transparent;
    color: #ffffff;
    font-size: 14px!important;
}
.react-datepicker-wrapper {
    width: 105%;
}
.input-schedule::placeholder {
    font-size: 14px;
}
/* upload image */
.schedule-upload-image {
    width: 100%;
    text-align: left;
}
input[type="file"] {
    display: none;
}

.custom-file-upload {
    display: inline-block;
    position: relative;
    cursor: pointer;
    background: #979797;
    margin-bottom: 25px;
}

.img-wrap{
    position: relative;
    width: 120px;
    height: 120px;
}
/* .img-upload:before{
    content: "\f093";
    font-size: 30px;
    position: absolute;
    padding-top: 60px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #ffffff;
    width: 150px;
    height: 150px;
    opacity: 0;
    transition: .5s ease;
    background-color: #979797;
}
.img-upload:hover:before{
   opacity: 1;
} */
.img-upload-sub {
    width: 120px;
    height: 120px;
    object-fit: cover;
}

.schedule-livestream-title {
    font-weight: bold;
    font-size: 16px;
    text-align: left;
}
.font-weight-bold {
    /* font-weight: bold; */
    font-size: 13px;
}
.schedule-label_custom {
    font-size: 13px;
}
.margin-auto {
    margin: 0 auto;
}
.ticket-title {
    font-size: 24px;
    font-weight: bold;
}
/*  */
.livestream-schedule-content {
    margin: 0 auto;
    padding: 3rem 1rem;
    color: #ffffff;
}
.expired-date {
    width: 100%;
    position: relative;
}
/* .expired-date div {
    width: 100%;
} */
.input__datime {
    width: 102%;
}
.expired-date img {
    position: absolute;
    right: 0;
    pointer-events: none;
}
.livestream-schedule-title {
    display: block;
    text-align: center;
    margin-bottom: 2rem;
    font-size: 36px;
}
.schedule-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.schedule-label label{
    font-size: 13px;
    /* font-weight: bold; */
}
.schedule-font-size {
    font-size: 14px;
}
p.livestream-schedule-text {
    margin-top: 0;
    font-size: 14px;
    font-weight: normal;
}

.livestream-schedule-radio {
    margin-top: 0;
    font-size: 14px;
    font-weight: normal;
    display: block;
}
.box-livestream__radio {
    width: 30%;
    justify-content: start;
}
.create-livestream__radio {
    margin-right: 15px;
}


.characters_left {
    font-size: 10px;
    font-weight: bold;
    color: #9B9B9B;
}
.schedule-date-time {
    display: flex;
    width: 98%;
    justify-content: space-between;
}
.schedule-date {
    width: 55%;
    /* margin-right: 1.2rem; */
    display: flex;
    align-items: center;
}
.schedule-date-img {
    margin-left: -2rem;
    width: 1.5rem;
    height: 1.5rem;
}
.schedule-time {
    width: 40%;
    display: flex;
    align-items: center;
}
/* button */
.schedule__but_return, .schedule__but_next {
    padding: 0.4rem 1.6rem;
    /* margin-right: 0.5rem; */
    font-size: 12px;
    width: 140px;
}
.schedule__but_next {
    background-color: #FFF765;
}
.schedule__but_return {
    background-color: #9B9B9B;
}

/* checkbox */
.schedule-category {
    border: 1px solid #ffffff;
    border-radius: 4px;
    padding: 0.8rem;
}
.schedule-category_invalid {
    border: 1px solid #dc3545;
}
.schedule-category__item {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin: 0.6rem;
}
.schedule-checkbox {
    border: 1px solid #FFF765;
}
label.container-checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
}
.container-checkbox input {
    opacity: 0;
    cursor: pointer;
}
.checkmark {
    position: absolute;
    height: 26px;
    width: 26px;
    border: 1px solid #FFF765;
    margin-left: -3px;
}
.checkmark_cus {
    position: relative;
    margin-top: 10px;
}
.checkmark__delivered {
    position: relative;
    height: 26px;
    margin-left: -1.5rem;
    background-color: #848484;
    margin-right: -21px;
    padding: 2px 6px;
    font-size: 14px;
    margin-top: 10px;
}
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
.container-checkbox input:checked ~ .checkmark:after {
    display: block;
}
.container-checkbox .checkmark:after {
    left: 2px;
    top: 2px;
    width: 10px;
    height: 10px;
    border: solid #FFF765;
    border-width: 10px;
}
/* selectbox */
.schedule_input__select {
    position: relative;
    margin-bottom: 54.2px;
}
#schedule-input-select {
    width: 100%;
    cursor: pointer;
    display: block;
    text-align: left;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #ffffff;
}
.schedule-select-options{
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    /* z-index: 999; */
    padding-left: 0.9rem;
    list-style: none;
    background-color: transparent;
    height: 2.2rem;
    border-radius: 4px;
    border: 1px solid #ffffff;
}
.schedule_caret {
    top: 2.6rem;
    right: 14px;
    pointer-events: none;
    color: #9B9B9B;
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 8px dashed;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    position: absolute;
}
.schedule-options_list-item {
    background-color: #979797;
}
.input-schedule:disabled {
    background-color: transparent;
}
/*  */
.schedule_box_width_60 {
    width: 70%;
}
.schedule-date__sale {
    align-items: center;
}
.schedule-btn_add_ticket {
    font-size: 12px;
    text-align: end;
}
/* DatePicker */
.react-datepicker__navigation--next:focus, .react-datepicker__navigation--previous:focus {
    outline: 0 auto -webkit-focus-ring-color;
}

/*  */
.schedule-create-ticket {
    font-size: 24px;
}
.ticket-icon {
    width: 16px!important;
    height: 16px!important;
    padding: 0!important;
}
.content-3 .ticket-icon {
    width: 14px!important;
    height: 12px!important;
}
.ticket-value {
    padding-left: 10px!important;
    word-break: break-all;
}
.ticket-value_cus {
    padding-left: 0px!important;
}
.ticket-value_event_edit {
    word-break: break-all;
}
.apply_purchase_content {
    width: 100%;
}
.livestream-schedule-content .buy__ticket {
    background-color: #ffffff;
    color: #000;
    padding: 10px 0px 10px 10px;
}
.length_tickets {
    height: 505px;
    overflow-y: scroll;
}
.schedule_btn_return_confirm {
    font-size: 12px;
    display: flex;
    justify-content: space-evenly;
    margin-top: 1rem;
}
.schedule_note {
    color: #F8E71C;
}
.schedule_note_2 {
    display: block;
}
.buy__ticket_disable {
    display: none;
}
.invalid__feedback {
    display: block;
    width: 100%;
    margin-bottom: 1rem;
    font-size: 12px;
    color: #dc3545;
}
.label__is-invalid {
    color: #dc3545;
}
.ticket_text_span {
    display: inline-grid;
    width: 93%;
}
.added-ticket-description {
    display: flex;
}
.ticket_text_community {
    display: flex;
    /* justify-content: center; */
    width: 100%;
}
.ticket__description {
    white-space: pre-wrap;
}

/* modal */
.modal-content__community {
    background-color: #383838 !important;
    color: #fff;
    border-radius: 0;
}
.modal-content_cus {
    width: 100% !important;
}
.community__title {
    font-size: 14px;
    text-align: center;
    display: block;
    margin-bottom: 1.5rem;
}
.community__body {
    font-size: 14px;
    padding-left: 10%;
    padding-right: 9%;
}
.community__body_hd {
    height: 300px;
    overflow-y: scroll;
}
.community_content__radio {
    margin-top: 2rem;
}
.community__radio {
    width: 100% !important;
    justify-content: start;
}
.community__radio label::before {
    background: #383838 !important;
}
.community__btn {
    display: flex;
    justify-content: space-evenly;
}
.btn-input-schedule {
    border: 1px solid #ffffff;
    background-color: transparent;
    color: #ffffff;
    text-align: left;
    font-size: 14px;
    font-weight: normal;
}
.btn-input-schedule:hover {
    color: #ffffff;
}
.btn-input-schedule:focus {
    background-color: transparent;
    color: #ffffff;
    border-color: #ffffff;
}

.display__none {
    display: none;
}

.added-ticket-price {
    margin-right: 0.1rem;
    width: 105px;
}
.added-ticket-expired {
    margin-right: 1.5rem;
    /* width: 128px; */
}
.ticket__content.content_3.ticket__content_custom {
    justify-content: unset;
}
.modal__input-schedule {
    width: 95%;
}

.bd_line_none {
    border-bottom: none !important;
}

/* .added-ticket-name.added-ticket-name__cus {
    font-weight: 600;
} */
.ticket-icon_description {
    position: relative;
    bottom: -4px;
}

/* price ticket */
select {
    -moz-appearance: none;
    -webkit-appearance: none;
}

select::-ms-expand {
    display: none;
}
.input__select_price {
    position: relative;
}
#schedule-input-select_price {
    width: 100%;
    cursor: pointer;
    display: block;
    text-align: left;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #ffffff;
}
.schedule-select-options_price {
    position: absolute;
    /* top: 100%; */
    right: 0;
    left: 0;
    /* z-index: 999; */
    padding-left: 0.9rem;
    list-style: none;
    background-color: transparent;
    height: 2.2rem;
    border-radius: 4px;
    border: 1px solid #ffffff;
}
.schedule_caret_price {
    top: 38%;
    right: 8px;
    pointer-events: none;
    color: #9B9B9B;
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 8px dashed;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    position: absolute;
}
.price_box_cus {
    display: flex;
    justify-content: space-between;
}
.price_cus {
    width: 48%;
}
/* button disable ticket */
.disable_ticket {
    text-align: left;
}
.btn_disable_ticket {
    padding: 0.4rem 1.6rem;
    font-size: 12px;
    background-color: #FFF765;
    width: 190px;
}
.price_cus {
    position: relative;
}

.display-xs {
    display: none;
}

/* .hide-xs {
    display: block;
} */

.no_fees {
    margin-bottom: 1.2rem;
}
@media screen and (max-width:1024px) {
    .hide-xs {
        display: block;
    }
    .event_pd_r {
        padding-right: 10px;
    }
}

@media (max-width: 768px) {
    .livestream-schedule-title {
        font-size: 28px;
    }
    .schedule_box_width_60 {
        width: 100%;
    }
    .schedule-date__sale {
        width: 99%
    }
    .input__datime {
        width: 101%;
    }
    .schedule-date-time {
        width: 99%;
    }
}
@media (max-width: 414px) {
    .livestream-schedule-title {
        font-size: 22px;
        margin-bottom: 1rem;
    }
    .input__datime {
        width: 102%;
    }
    .schedule-date-time {
        width: 98%;
    }
    .ticket__content_custom div {
        width: 100%;
    }
    .display-xs {
        display: block;
    }

    .hide-xs {
        display: none;
    }
    .btn_disable_ticket {
        padding: 0.4rem 0.6rem;
        font-size: 12px;
        background-color: #FFF765;
        width: 160px;
        float: right;
    }
    .display_ticket_mobile {
        display: flex;
        justify-content: flex-end;
    }
    .schedule-livestream-title {
        font-size: 14px;
    }
    .livestream-schedule-content {
        padding: 2rem 1rem;
    }
    .ticket-title {
        font-size: 18px;
    }
    .schedule-create-ticket {
        font-size: 20px;
    }
    .modal-content__community {
        width: 98% !important;
    }
    .schedule__but_return, .schedule__but_next {
        width: 127px;
    }
}

@media (max-width: 320px) {
    .schedule__but_return, .schedule__but_next {
        font-size: 10px;
    }
}

