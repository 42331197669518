.forget_register__span1 {
    padding-left: 30px;
}
.forget_register__span2 {
    padding-left: 37px;
}
@media (max-width: 768px) {
    .forget_register__span1 {
        padding-left: 0;
    }
}
@media (max-width: 414px) {
    .progress__text {
        font-size: 10px !important;
    }
}
@media (max-width: 320px) {
    .progress__text {
        font-size: 9px !important;
    }
}
