
.stop_display_text {
    flex-direction: column;
}
.display_buy__ticket_header {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.display_buy__ticket_img {
    width: 30%;
}
.display_buy__ticket_img img {
    width: 100%;
    height: 200px;
}
.display_event__btn {
    width: 50%;
    margin-top: 1rem;
    display: flex;
    justify-content: space-around;
}

/* display timer */
h5.text_countdown_timer {
    padding-bottom: 10px;
}
.time-wrapper {
    text-align: center;
}
.box_countdown_timer {
    position: absolute;
    width: 100%;
    height: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: 1000;
    color: #fff;
    background-color: rgba(0,0,0,0.75);
}
span.countdown_timer {
    margin: 0 5px;
}
.countdown_timer_content {
    display: flex;
    /* background-color: rgba(0,0,0,0.75); */
}

.hidden__box_chat {
    background: #000000;
    position: relative;
    min-height: 682px;
}

.displayBlockBoxChat {
    display: block;
}

.displayNoneBoxChat {
    display: none;
}

@media screen and (max-width: 992px) {
    .video__stop_livestream_mobile .box_countdown_timer {
        height: 0;
        top: 50%;
    }
    .par_box_chat {
        position: relative;
        width: 100% !important;
    }
    .show_par_box_chat {
        height: 682px;
    }
    .hide_par_box_chat {
        height:unset;
    }
    .set_box {
        min-height: 454px !important;
    }
    .unset_box {
        min-height: unset !important;
    }
}

@media screen and (max-width: 768px) {
    .box__buy__ticket_text {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
    }
    .event__but_next_mobile, .event__but_return_mobile {
        padding: 0.4rem;
        font-size: 12px;
        font-weight: bold;
        width: 100px;
    }
    .event__but_return_mobile {
        background-color: #9B9B9B;
    }
    .event__but_next_mobile {
        background-color: #FFF765;
    }
    .display_event__btn_mobile {
        width: 70%;
        margin-top: 1rem;
        display: flex;
        justify-content: space-around;
    }
    .display_buy__ticket_img img {
        height: 150px;
    }
    .set_box {
        min-height: 368px !important;
    }
}
@media screen and (max-width: 480px) {
    .hidden__box_chat {
        min-height: 0 !important;
    }
    .box_countdown_timer h5 {
        font-size: 0.8rem;
    }
    .time-wrapper {
        font-size: 10px;
    }
    .set_box {
        min-height: 200px !important;
    }
    .show_par_box_chat {
        height: 345px;
    }
    .hide_par_box_chat {
        height:unset;
    }
    .tab__box_chat {
        padding: .1rem 1rem;
    }
}

@media screen and (max-width: 414px) {
    .display_buy__ticket_img img {
        height: 100px;
    }
    .set_box {
        min-height: 190px !important;
    }
}

@media screen and (max-width: 375px) {
    .set_box {
        min-height: 170px !important;
    }
    .show_par_box_chat {
        height: 291px;
    }
}

@media screen and (max-width: 360px) {
    .set_box {
        min-height: 165px !important;
    }
}

@media screen and (max-width: 320px) {
    .set_box {
        min-height: 145px !important;
    }
}
