.container_list__event {
    margin: 0 auto 1rem;
    padding-top: 2rem;
    width: 65%;
}
.event__detail_title {
    color: #ffffff;
    font-size: 36px;
    /* font-style: italic; */
    margin-bottom: 2rem;
    text-align: center;
}
.best_event_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid gray;
    margin: 1rem 0;
    margin-top: 0;
}
.best_event_title {
    font-size: 16px;
    color: #000;
    background-color: #FFF765;
    border-color: #FFF765 #FFF765 #FFF765;
    padding: 11px 15px;
    margin-left: 15px;
    margin-bottom: -1px;
}

.number__event {
    margin-right: 15px;
    font-size: 14px;
    font-weight: inherit;
    color: #9B9B9B;
}

.best_event_box {
    background-color: #626262;
    /* width: 100%; */
    display: flex;
    margin: 10px 15px
}
.best_event_box_img {
    /* flex: 0 0 30%; */
    display: flex;
    width: 30%;
}
.best_event_box_img img {
    width: 60%;
    height: 171px;
    /* margin-top: -45px; */
    object-fit: cover;
}
.best_event_heart_start {
    background-color: #848484;
    color: #848484;
    font-size: 10px;
    height: 57px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 61px;
}
.best_event_heart_start span {
    color: #D0D0D0;
    text-align: center;
    font-size: 10px;
}
.best_event_box_img_heart {
    position: relative;
    background-color: #efefef;
    width: 77px;
    height: 77px;
}
.i_heart {
    position: absolute;
    right: 20px;
    top: 22px;
    padding: 5px 10px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.2);
}
.best_event_box_img_start {
    position: relative;
    background-color: #bbbbbb;
    color: #d7d7d7;
    font-weight: bold;
    height: 123px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.best_event_box_img_start span {
    display: block;
}
a.img_start {
    font-size: 18px;
    color: #FFF765;
    cursor: default;
    line-height: 1;
}

.best_event_content_day {
    display: flex;
    justify-content: space-between;
    width: 70%;
}
.best_event_content_daytime {
    flex: 0 0 75%;
    /* height: 3.5rem; */
    line-height: 2.5rem;
    padding-bottom: 0.5rem;
}
.content_daytime__img {
    width: 18px;
    margin-top: -5px;
}
.content_daytime__color {
    color: #ffffff;
    font-size: 13px;
}
.badge {
    padding: 5px 4px;
    vertical-align: text-bottom;
    margin-right: 5px;
}
.badge-dark, .pill-dark {
    background-color: transparent;
    border: 1px solid #FFF765;
}
a.badge-dark:focus, a.badge-dark:hover {
    background-color: transparent;
}

.best_event_content_title {
    color: #FFF765;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
    line-height: 1;
    cursor: pointer;
    word-break: break-all;
}
.best_event_content_title.not_cursor_pointer {
    cursor: unset;
}

.best_event_copy_right {
    color: #8E8E8E;
    font-size: 13px;
    line-height: 1.5rem;
    margin-top: 0!important;
    margin-right: 5px;
    word-break: break-all;
    white-space: pre-wrap;
}
.best_event_content_content {
    line-height: 1.5rem;
    color: #ffffff;
    font-size: 13px;
}

/* coupon tag */
.coupon__tag {
    text-align: center;
    cursor: pointer;
    padding: 12px;
    border: 1px solid #FFF765;
    background-color: #FFF765;
    font-size: 13px;
    height: max-content;
}

/* list_event */
.container_list_event {
    /* overflow-y: auto; */
    display: flex;
    flex-wrap: wrap;
}
.list_event {
    flex: 0 0 21%;
    margin: 0 2%;
    position: relative;
    width: 21%;
}
.list_event_img {
    position: relative;
}

.list_event_img img {
    width: 100%;
    height: 300px;
    object-fit: cover;
}
.list_event_title {
    font-size: 16px;
    color: #ffffff;
    cursor: pointer;
    padding-top: 10px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.list_event_badge {
    padding-bottom: 12px;
}
.list_event_img_start{
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    font-weight: bold;
    height: 20%;
    padding-left: 0.2rem;
    padding-right: 0.2rem;
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.4);
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.number_start, .number_view {
    padding: 0 2px;
}
.list_event_time {
    width: 85px;
    text-align: center;
    top: 0 !important;
    left: 0;
    border: none;
    box-shadow: none;
    border-radius: 0 !important;
    padding: 5px;
    position: absolute;
    background: #000000;
    opacity: 0.7;
    color: #ffffff;
    font-size: 10px;
}
.list_event_notification {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 10px;
    font-weight: normal;
    line-height: 17px;
    color: #000000;
    padding: 5px;
    background-color: #FFF765;
    border: none;
    box-shadow: none;
    border-radius: 0;
    width: 80px;
}
.list_event_un_notification {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 10px;
    font-weight: bold;
    line-height: 17px;
    padding: 5px;
    border: none;
    box-shadow: none;
    border-radius: 0;
    background-color: #000000;
    color: #FFF765;
    opacity: 0.7;
    width: 60px;
}
.list_event_notification:focus, .list_event_un_notification:focus {
    outline: 0;
}

.badge__mg_top {
    margin-top: 3px;
}
.list_event_btn {
    margin-top: 3px;
    border: 1px solid #9b9b9b;
    background-color: #9B9B9B;
    padding: 5px 4px;
    vertical-align: text-bottom;
    display: table;
    font-size: 10px;
    /* font-weight: 700; */
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    color: #ffffff;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.btn_list_event_load_more {
    font-weight: 400;
    border-radius: 0;
    padding: 7px 0;
    color: #fff67a;
}
.btn_list_event_load_more:hover {
    color: #fff67a;
}
.btn_prev_next_day {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 2rem;
    padding-top: 2rem;
}
button.btn_prev_day, button.btn_next_day {
    background-color: #575757;
    color: #FFF765;
    border: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    padding: 7px 13px;
}
.btn_prev_day:focus, .btn_next_day:focus {
    outline: 0;
}

.btn_prev_day i.fas.fa-chevron-left, .btn_next_day i.fas.fa-chevron-right {
    font-size: 23px;
    color: #9a9a9a;
}
.btn_prev_day i.fas.fa-chevron-left {
    padding-right: 4px;
}

@media (max-width: 1024px) {
    .best_event_box_img {
        width: 45%;
    }
    .best_event_box_img_heart {
        width: 72px;
    }
    .event__detail_title {
        font-size: 1.8rem;
    }
    .best_event_content_daytime {
        line-height: 2rem;
    }
    .best_event_content_title {
        font-size: 16px;
        font-weight: bold;
    }
    .best_event_copy_right {
        margin-top: 0!important;
    }
    .best_event_content_content {
        padding-bottom: 0.5rem;
    }
    .coupon__tag {
        font-size: 12px;
    }
    .container_list__event {
        width: 94%;
    }
}
@media (max-width: 768px) {
    .container_list__event {
        width: 91%;
    }
    .event__detail_title {
        margin-left: 0.5rem;
    }
    .list_event {
        flex: 0 0 44%;
        margin: 0 3% 5%;
        width: 44%;
    }
    a.img_start {
        font-size: 18px;
    }
    .best_event_box_img_start {
        font-size: 11px;
    }
    .coupon__tag {
        font-size: 11px;
    }
    .coupon__tag {
        padding: 11px;
    }
    .best_event_content_daytime {
        flex: 0 0 70%;
    }
}
@media (max-width: 568px) {
    .coupon__tag {
        font-size: 10px;
    }
}
@media (max-width: 414px) {
    .event__detail_title {
        font-size: 18px;
    }
    .best_event_box {
        flex-direction: column;
    }
    .best_event_box_img {
        width: 100%;
        position: relative;
    }
    .best_event_content_day {
        width: 100%;
        margin-top: 1rem;
        padding-left: 0.5rem;
    }
    .list_event {
        flex: 0 0 90%;
        margin: 0 5% 5%;
        position: relative;
        width: 90%;
    }

    span.best_event_heart_start {
        width: 20%;
        display: flex;
        flex-direction: column;
        position: absolute;
        right: 0;
    }
    .best_event_box_img img {
        width: 100%;
        /* height: auto; */
    }
    .coupon__tag {
        padding: 2px 0 0;
        font-size: 11px;
        line-height: 1.3rem;
        height: 1.6rem;
    }
    .best_event_content_daytime {
        font-size: 12px;
    }
    .btn-create-livestream {
        margin-right: 0!important;
    }
    .best_event_content_title_cus {
        word-break: break-all;
        width: 140%;
    }
    .btn_prev_next_day {
        font-size: 14px;
        padding-bottom: 0;
        padding-top: 0;
    }
}
