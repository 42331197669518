.content__calendar {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.content__calendar span {
    margin-right: 0.5rem !important;
}
.content__calendar_img {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 15%;
    height: 35px;
    cursor: pointer;
}
.content__calendar_img svg {
    width: 100%;
}
.content__calendar_img svg:hover {
    color: #000000;
}

/* calendar */
.top__calendar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 130px;
}
.top__calendar_height {
    height: 18px;
}
.nav-item__menu {
    display: flex;
    align-items: center;
    justify-content: center;
}
.nav-link__menu {
    font-size: 14px !important;
}

.text__year, .text__day, .text__month {
    font-size: 14px;
    margin-right: 3px;
}

.text__day_mr {
    margin-right: 10px;
}

.react-datepicker {
    border-radius: 0 !important;
}
.react-datepicker-popper[data-placement^="bottom"] {
    margin-top: 0 !important;
    left: -3px !important;
}
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    border-bottom-color: transparent !important;
}
.react-datepicker__header {
    background-color: unset !important;
    border-bottom: 0 !important;
    border-top-left-radius: 0 !important;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
    padding: 1rem;
}
.react-datepicker__navigation--previous, .react-datepicker__navigation--next {
    top: 1.8rem;
}
.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
    border-radius: 0 !important;
    background-color: #acacac !important;
}
.react-datepicker__day--selected {
    border-radius: 0 !important;
    background-color: #acacac !important;
}
.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover {
    border-radius: 0 !important;
    background-color: #e6e7e8;
}
.react-datepicker__day:focus {
    border: 0 !important;
}
.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
    padding: 0.5rem 0.5rem 0;
}

.item__menu_margin {
    margin-left: 10rem;
}
.react-datepicker__day--disabled {
    cursor: default;
    color: #9e9ea0;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background-color:#acacac;
    color: #696969;
    font-weight: bold;
}

.livestream__box:hover {
    background-color: #000000 !important;
}
.livestream__menu:hover {
    color: #ffffff !important;
}

@media screen and (max-width:1024px) {
    /* header */
    #navbarText {
        flex: 0 0 74% !important;
    }
    .item__menu_margin {
        margin-left: 2rem;
    }
    .react-datepicker-popper[data-placement^="bottom"] {
        margin-top: 0px !important;
        left: -4px !important;
    }
}

@media (max-width: 992px) {
    .small-calendar {
        margin-top: -8px !important;
    }
    .top__calendar {
        width: unset;
    }
    .content__calendar_img {
        width: 3%;
        height: 21px;
    }
    .text__year, .text__day, .text__month {
        font-size: 16px;
    }
    li.nav-item.small-calendar.active {
        padding: 15px;
    }
    li.nav-item.small-calendar.active.livestream__box {
        padding: 8px;
    }
    ul.menu__center.navbar-nav.mr-auto.mt-2.mt-lg-0 {
        width: 100%;
    }
    .item__menu_margin {
        margin-left: 0;
    }
}

@media (max-width: 812px) {
    /* scroll calendar */
    .react-datepicker {
        display: flex;
        overflow-x: scroll;
        white-space: nowrap;
        scrollbar-width: thin;
        scrollbar-color: #212529 #d3d3d3;
    }
    .react-datepicker::-webkit-scrollbar {
        background-color: #212529;
        border-radius: 4px;
        height: 6px;
    }

    .react-datepicker::-webkit-scrollbar-track {
        background: #d3d3d3;
        border-radius: 4px;
    }

    .react-datepicker::-webkit-scrollbar-thumb {
        background-color: #212529;
        border: 1px solid transparent;
        border-radius: 4px;
    }

    .react-datepicker::-webkit-scrollbar-thumb:hover{
        background-color: #212529;
    }

    /*  */
    .schedule-date .react-datepicker, .schedule-time .react-datepicker, .expired-date .react-datepicker {
        overflow-x: unset;
    }
    .react-datepicker-popper[data-placement^="bottom"] {
        margin-top: 15px !important;
        left: -15px !important;
    }
    .react-datepicker__navigation--previous, .react-datepicker__navigation--next {
        position: fixed;
    }
    .item__menu_margin {
        margin-left: 0;
    }
    li.nav-item.nav-item__menu {
        width: 100%;
    }
    a.nav-link.nav-link__menu {
        width: 100%;
        text-align: center;
    }
    ul.menu__center.navbar-nav.mr-auto.mt-lg-0 {
        width: 100%;
        margin-top: 8px;
    }
    li.nav-item.small-calendar.active.livestream__box {
        width: 100%;
    }
    a.nav-link.nav-link__menu.livestream__menu {
        width: 100%;
        text-align: center;
    }
}
@media (max-width: 480px) {
    .content__calendar_img {
        width: 4%;
    }
    .content__calendar_img svg {
        width: 97%;
    }
    .text__year, .text__day, .text__month {
        font-size: 14px;
    }
    .react-datepicker-popper[data-placement^="bottom"] {
        margin-top: 8px !important;
        left: -8.5px !important;
        max-width: 100%;
    }
    li.nav-item.small-calendar.active {
        padding: 0;
    }
    /* li.nav-item.small-calendar.active {
        padding: 8px;
    } */
    li.nav-item.small-calendar.active.livestream__box {
        padding: 1px;
    }
    menu__center li {
        padding: 0.3rem;
    }
    .nav-link__menu {
        font-size: 13px !important;
    }
    .menu__center li a {
        line-height: 20px;
    }
    li.nav-item.nav-item__menu {
        padding: 9px;
    }
}
@media (max-width: 320px) {
    .content__calendar_img {
        width: 5%;
    }
    .expired-date .react-datepicker {
        overflow-x: scroll;
    }
}

