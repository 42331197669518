/*MAIN CSS*/

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


/* Works on Chrome, Edge, and Safari -- scrollbar*/

*::-webkit-scrollbar {
    width: 5px;
}

*::-webkit-scrollbar-track {
    background: lightgray;
}

*::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 20px;
    border: 2px solid gray;
}

input[type=number] {
    -moz-appearance: textfield;
}

select:focus,
input:focus {
    outline: none;
}

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap");
a:hover {
    text-decoration: unset;
}

.btn:focus {
    outline: 0;
    box-shadow: unset;
}

.hidden {
    display: none !important;
}

body {
    font-size: 14px;
    font-family: 'Noto Sans JP', sans-serif;
}

.navbar__custom {
    background: #000;
}

.embed__source iframe {
    height: 647px;
}

.navbar__custom .dropdown-menu {
    background: #C2C2C2;
    border-radius: 0;
    padding: 0;
}

.user__actions .btn {
    color: #858585;
}

.dropdown-toggle {
    padding: 0;
}

.user__actions .dropdown-toggle::after {
    border-top: .5em solid;
    border-right: .5em solid transparent;
    border-left: .5em solid transparent;
    vertical-align: baseline;
}

.menu__right.user__actions {
    font-size: 14px;
    letter-spacing: 0.56px;
    color: #FFFFFF;
}

.user__actions .dropdown-menu {
    min-width: 8rem;
}

.user__actions .dropdown-menu .dropdown-item {
    padding: .5rem 3.5rem;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.56px;
    color: #000000;
    border-bottom: 1px solid #979797;
}

.user__actions span {
    color: #fff;
    margin-right: 3px;
}

.navbar__custom .navbar-brand img {
    width: 24px;
    height: 24px;
}

.home__page {
    position: relative;
}

.home__page .home__image {
    width: 100%;
    height: calc(100vh - 40px);
}

.list__livestream_login {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
}

.list__livestream_login ul {
    padding: 0;
    margin: 0;
}

.list__livestream_login ul li {
    cursor: pointer;
    list-style: none;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 4px;
    margin: 0 auto 16px;
    width: 311px;
    text-align: center;
    position: relative;
    padding: 16px;
    line-height: 1;
}

.loginGroup li {
    display: inline-block;
}

.list__livestream_login ul.login__bg_w li {
    background: #fff;
}

.list__livestream_login ul.login__bg_w li:hover {
    background: #fff;
    transition: .5s;
}

.list__livestream_login ul.login__bg_w li img.profile {
    -webkit-filter: grayscale(0) invert(1);
    filter: grayscale(0) invert(1);
    -webkit-transition: all 0.2s ease-in-out;
}

.list__livestream_login ul.login__bg_w li a span {
    color: #000;
}

.list__livestream_login ul li:hover {
    background: rgb(0 0 0 / 82%);
    transition: .5s;
}

.list__livestream_login ul li img {
    position: absolute;
    left: 1rem;
    top: 1rem;
}

.list__livestream_login ul li span {
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.56px;
    color: #FFFFFF;
}

.login__form {
    width: 310px;
    margin: 0 auto;
}

.locate__input {
    width: 100%;
}

.locate__input select {
    color: #fff;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 4px;
    padding: 15px 25px 15px 15px;
    border: rgba(0, 0, 0, 0.6);
    cursor: pointer;
}

.locate__input input {
    background: rgba(0, 0, 0, 0.6);
    border-radius: 4px;
    border: rgba(0, 0, 0, 0.6);
    padding: 1rem;
    color: #9B9B9B;
}

.input__password input {
    background: rgba(0, 0, 0, 0.6);
    border-radius: 4px;
    border: rgba(0, 0, 0, 0.6);
    padding: 15px;
    color: #9B9B9B;
    width: 100%;
}

.input__select .caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 8px dashed;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    position: absolute;
    top: 23px;
    right: 10px;
    margin-top: -2px;
    color: #fff;
}

.input__select {
    position: relative;
    display: flex;
    flex: 0 0 64%;
}

.input__select select {
    -moz-appearance: none;
    -webkit-appearance: none;
}

.input__select select::-ms-expand {
    display: none;
}

.input__login {
    text-align: right;
}

.input__login input {
    background: #FFF765;
    border-radius: 4px;
    border: none;
    padding: 15px 26px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 2.35846px;
    color: #000000;
}

.input__login input:hover {
    background: #fff42d;
    transition: .5s;
}

.heading_livedream {
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #FFFFFF;
    word-break: break-all;
}

.sub__content {
    text-align: center;
    margin-bottom: 2rem;
}

.sub__content_reset {
    text-align: center;
}

.sub__content span,
.sub__content_reset span {
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.56px;
    color: #FFFFFF;
}

.link__login {
    margin-top: 2rem;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.56px;
    color: #FFFFFF;
}

.link__login a {
    text-decoration-line: underline;
    color: #FFF765;
}


/* My Livestream */

.section_livestream_info {
    border-bottom: 1px solid gray;
    padding: 1rem 0;
    margin: 1rem 0;
    margin-top: 0;
}

.livestream__infor {
    width: 100%;
}

.section_livestream_info .stream__info {
    width: 85%;
}

.section_livestream_info .stream__info .heading_livedream {
    text-align: left;
}

.section_livestream_info .meta_info span i {
    padding-right: 3px;
}

.section_livestream_info .acction_share span {
    padding-left: 0.5rem;
    cursor: pointer;
}

.section_livestream_info .acction_share span i {
    padding-left: 5px;
    font-size: 17px;
}

.section_livestream_info .meta_info span {
    cursor: pointer;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 17px;
    color: #FFFFFF;
    margin: 0;
    padding-right: 0.5rem;
}

.section_livestream_info a,
.section_livestream_info {
    color: #fff;
}

.stream_description .author__name {
    color: #fff;
    padding-left: 0.5rem;
}

.stream_description .author__name h6 {
    margin: 0;
    font-size: 14px;
}

.stream_description .author__name span {
    font-size: 10px;
    line-height: 14px;
    color: #FFFFFF;
    mix-blend-mode: normal;
    opacity: 0.4;
}

.author_detail {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    margin: 0;
    color: #FFFFFF;
    mix-blend-mode: normal;
    opacity: 0.7;
    word-break: break-all;
}

.stream_description {
    position: relative;
    margin-bottom: 1rem;
}

.stream_description .btn__follow_chanel {
    position: absolute;
    background: #FFF765;
    top: 0;
    right: 0;
    font-size: 13px;
    border-radius: 0;
    padding: 5px 32px;
    font-weight: bold;
}


/*Register */

.form_register .login__form {
    margin: 0 auto;
}

.input__phone {
    display: flex;
    flex: 0 0 35%;
    justify-content: flex-end;
}

.form_register .input__phone {
    text-align: right;
    display: flex;
    flex: 0 0 67%;
    justify-content: flex-end;
}

.form_register .locate__input input {
    width: 90%;
}

.list_progress_login {
    width: 735px;
    margin: 2rem 0;
}

.progress__line {
    padding-left: 4.1875rem;
    padding-right: 2.875rem;
}

.progress__line .cicle_dots {
    position: relative;
    background: #FFFFFF;
    border: 1px solid #000000;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.0877491);
    width: 25px;
    height: 25px;
    border-radius: 50%;
}

.progress__line .cicle_dots:before {
    position: absolute;
    content: "";
    width: 5px;
    height: 5px;
    background: #000000;
    border-radius: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 40%;
}

.progress__line .line {
    border: 2px solid #FFFFFF;
    width: 40.515%;
}

.progress__text {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 2.02154px;
    color: #FFFFFF;
    margin: 1rem 0;
}

.progress__line .cicle_dots.active {
    border: 1px solid #FFF765;
}

.progress__line .cicle_dots.active:before {
    background: #FFF765;
}

.progress__line .line.active {
    border: 2px solid #FFF765;
}

.progress__text span.active {
    color: #FFF765;
}

.form_register fieldset {
    display: none;
}

.form_register fieldset:first-child {
    display: block;
}

.input__verify_code {
    margin-bottom: 2rem;
}

.locate__input .input__verify_code input {
    border: none;
    background: transparent;
    border-bottom: 1px solid #FFF765;
    border-radius: 0;
    width: 50px;
    padding: 5px;
    font-size: 17px;
    color: #fff;
    text-align: center;
}

.step_register input {
    margin-bottom: 1rem;
}

.sub__note {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #FFFFFF;
}

.sub__note a {
    color: #FFF765;
}


/* Menu new*/

#my__navbar {
    padding-top: 0;
    padding-bottom: 0;
}

.menu__right,
#menu {
    width: 100%;
}

.menu__logo {
    flex: 0 0 7%;
}

.user__actions_custom {
    display: flex;
    align-items: center;
}

.nav-link__login {
    display: flex;
    /* padding: .7rem; */
    align-items: center;
    padding: 0 1rem;
}

span.menu__login {
    padding-right: 10px;
}

.menu__center li.nav-item.active {
    background: #FFF765;
    color: #000000;
}

.menu__center li.nav-item.active a {
    color: #000;
}

.menu__center li {
    padding: 0 3px;
}

.menu__center li a {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.64px;
    color: #FFFFFF;
    padding: 0;
}

html.homepage .menu__center li {
    padding: 0 3px;
}

.navbar-brand {
    padding-top: 0 !important;
}

html.homepage .navbar-brand {
    padding-top: 5px;
    padding-bottom: 5px;
    margin-right: 16px;
    font-size: 20px;
}

.menu__center li.nav-item:hover a {
    color: #000;
}

.menu__center li.nav-item:hover {
    background: #FFF765;
    color: #000000;
}


/* Home Page */

.box__live_streams {
    position: relative;
}

.home__page_live {
    background: #464646;
}

.box__video {
    background: #000000;
    position: relative;
    min-height: 682px;
}

.par_box_chat {
    /* flex: 0 0 26%; */
    width: 26%;
}

.box__chat {
    flex: 0 0 26%;
    background: #000000;
}

.content__box_chat {
    background: #000000;
    padding: 1rem;
    height: 637px;
    position: relative;
    padding-bottom: 0.5rem;
}

.list_chat {
    padding: 0;
    margin: 0;
    padding-left: 1rem;
}

.list_chat li {
    padding-bottom: 1rem;
    align-items: center;
}

.list_chat .user_info {
    padding-left: 1rem;
}

.list_chat .user_info .name {
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;
}

.list_chat .user_info .comment {
    padding-right: 5px;
    padding-top: 5px;
    font-size: 10px;
    line-height: 14px;
    color: #FFFFFF;
}

.box_type_chat {
    padding-top: 1rem;
    height: 13%;
}

.box_type_chat button.show_emoji {
    margin-left: 0.5rem;
}

.box_type_chat button {
    background: transparent;
    width: 32px;
    height: 32px;
    border: 1px solid #fff;
    border-radius: 50%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
}

.box_type_chat button#sentMess {
    border: none;
    width: unset;
    height: unset;
}

.type__comment {
    display: flex;
    padding: 5px 5px;
    border: 1px solid #fff;
    border-radius: 5px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.type__comment input {
    background: transparent;
    border: none;
    padding: 5px;
    color: #fff;
    font-size: 14px;
    width: 100%;
}

.type__comment i {
    color: #fff;
    margin-right: 5px;
    font-size: 14px;
}

.button__icon {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.video__related .heading_livedream {
    padding: 1rem 0;
}

h5.video__title {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 17px;
    color: #FFFFFF;
    margin: 0;
    padding: 0.5rem 0;
    overflow: hidden;
    word-break: break-all;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.cursor_pointer {
    cursor: pointer;
}

.cursor_pointer_default {
    cursor: default;
}

.meta__video span {
    font-size: 10px;
    line-height: 14px;
    color: #FFFFFF;
    mix-blend-mode: normal;
    opacity: 0.4;
}

.list__video_related {
    display: flex;
    flex-wrap: wrap;
}

#list_livestream_detail {
    width: 100%;
    overflow-y: overlay;
    flex-wrap: nowrap;
}

.list__video_related::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
}

.list__video_related .item__video {
    margin-bottom: 1.5rem;
    margin-right: 2%;
    width: 15%;
    position: relative;
}

.list__video_related .item__video:nth-child(6n) {
    margin-right: 0;
}

.video {
    position: relative;
}

.video time {
    position: absolute;
    bottom: 8%;
    right: 8%;
    font-size: 10.864px;
    line-height: 15px;
    text-align: right;
    color: #FFFFFF;
}

.video img {
    width: 100%;
    height: 300px;
    object-fit: cover;
}

.group__button button {
    background: transparent;
    border: none;
    padding: 0;
}

.user__no_login {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.9);
    z-index: 999;
}

.user__no_login span {
    font-size: 36px;
    line-height: 49px;
    text-align: center;
    letter-spacing: 1.44px;
    color: #FFFFFF;
}

.user__no_login a {
    text-decoration-line: underline;
    color: #FFF765;
}

button#show_box_chat,
button#hide_box_chat {
    position: relative;
    background: #1D1D1D;
    border: none;
    width: 100%;
    padding: 5px;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.56px;
    color: #FFFFFF;
}

button#hide_box_chat::after {
    position: relative;
    top: 3px;
    border-top: .6em solid;
    border-right: .6em solid transparent;
    border-left: .6em solid transparent;
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
}

button#show_box_chat::after {
    position: relative;
    top: 3px;
    border-bottom: .6em solid;
    border-right: .6em solid transparent;
    border-left: .6em solid transparent;
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
}

button#show_box_chat {
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
}

.button__icon .dropdown-toggle::after {
    display: none;
}

#tabsCoins.popup__show {
    margin-top: 1.5rem;
    position: relative;
    padding-top: 0;
}

#tabsCoins.popup__show ul {
    justify-content: flex-start;
    width: 100%;
    margin: 0 11%;
}

#tabsCoins.popup__show ul.add_new_coins {
    justify-content: center;
    margin: 0;
    margin-top: 3rem;
}

#tabsCoins.popup__show ul li {
    padding: 0;
    list-style: none;
    flex: 0 0 26%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    background: #262626;
    mix-blend-mode: normal;
    border: 1px solid #000000;
    box-sizing: border-box;
}

#tabsCoins.popup__show ul li button {
    padding: 5px 0.5rem;
    min-height: 4rem;
}

#tabsCoins.popup__show ul li button img {
    width: 50%;
}

.popup__show ul li {
    padding: 1rem;
    list-style: none;
    flex: 0 0 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    background: #262626;
    mix-blend-mode: normal;
    border: 1px solid #000000;
    box-sizing: border-box;
}

.popup__show {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    background: #000;
    padding-top: 2rem;
    min-height: 614px;
    overflow: hidden;
}

.popup__show ul {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.popup__show ul li span {
    font-size: 10px;
    line-height: 12px;
    color: #FFFFFF;
    margin-top: 0.5rem;
}

.popup__show a.close {
    width: 22px;
    height: 22px;
    right: 5px;
    top: 2.5rem;
    position: absolute;
    z-index: 1;
    border: 1px solid #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}


/* Break Scrum */

.yellow__button {
    background: #FFF765;
    font-size: 13px;
    border-radius: 0;
    padding: 0.5rem 1rem;
    font-weight: bold;
}

.section__break_scrum {
    padding: 0.5rem 0;
}

.break__scrum i.fa-home {
    font-size: 20px;
}

.break__scrum .fa-chevron-right {
    margin: 0 5px;
    display: block;
}

.break__scrum a {
    font-size: 13px;
}

.break__scrum a,
.break__scrum {
    color: #fff;
}


/* List Livestream */

#tab__list_livestream.nav-tabs .nav-link.active {
    color: #000;
    background-color: #FFF765;
    border-color: #FFF765 #FFF765 #FFF765;
}

#tab__list_livestream.nav-tabs .nav-link {
    color: #fff;
    padding: .5rem 2rem;
    text-align: center;
}

#tab__list_livestream.nav-tabs .nav-link:hover {
    color: #000;
    background-color: #FFF765;
    border-color: #FFF765 #FFF765 #FFF765;
}

#tab__list_livestream {
    border-bottom: 1px solid transparent;
}

#tabs_livesteam,
#tabs_livesteam2 {
    border-bottom: 1px solid gray;
    margin: 1rem 0;
    margin-top: 0;
}

.list__video_related .item__video .live {
    position: absolute;
    top: 0;
    border: none;
    box-shadow: none;
    border-radius: 0 !important;
    padding: 5px !important;
    background: #D0021B;
    font-size: 10px;
    line-height: 17px;
    color: #FFFFFF;
}


/* New Convertion tabs */

#convertionTabs.nav-tabs .nav-item {
    margin-bottom: 0;
    display: flex;
    flex: 0 0 50%;
    justify-content: center;
}

#convertionTabs.nav-tabs .nav-link {
    background-color: transparent;
    border-color: transparent;
    width: 100%;
    text-align: center;
}

#convertionTabs.nav-tabs .nav-link.active {
    border-bottom: 2px solid #dee2e6;
}

#convertionTabs.nav-tabs .nav-link .donate-img {
    min-height: 20px;
}

#convertionTabs.nav-tabs {
    border-bottom: 1px solid #dee2e661;
}

#showBoxChat {
    position: absolute;
    top: 45%;
    color: #dee2e661;
    right: -8px;
    font-size: 25px;
}

#fullScreen {
    position: absolute;
    top: 45%;
    color: #dee2e661;
    right: -2rem;
    font-size: 25px;
    z-index: 1;
}

#showBoxChat:hover,
#fullScreen:hover {
    color: #fff;
}

#hideBoxChat {
    display: none;
}

.small_img {
    width: auto;
}

#inputGroupSelect04 {
    width: 100%;
    cursor: pointer;
    display: block;
    text-align: left;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #9B9B9B;
    top: 0;
}

#inputGroupSelect04 option {
    width: 100%;
    background-color: #fff;
    color: #000;
    border-top: 1px solid gray;
    padding: 0.5rem;
    padding-right: 1rem;
}


/*Share Button*/

.share_modal img {
    width: 60px;
    height: auto;
}

#modalShare .modal-title {
    line-height: 1;
    color: #ffffff;
    text-align: center;
    width: 100%;
}

#modalShare .modal-content {
    background-color: #212121;
}

#modalShare ul {
    padding: 0;
}

#modalShare ul li {
    list-style: none;
    margin: 0 1rem;
}

#modalShare button.close span {
    color: #fff;
    opacity: 1;
}

#modalShare button.close {
    opacity: 1;
    font-weight: 100;
}


/* Modal Report */

.report_modal img {
    width: 60px;
    height: auto;
}

#modalReport .modal-title {
    line-height: 1;
    color: #ffffff;
    text-align: center;
    width: 100%;
}

#modalReport .modal-content {
    background-color: #212121;
}

#modalReport ul {
    padding: 0;
}

#modalReport ul li {
    list-style: none;
}

#modalReport button.close span {
    color: #fff;
    opacity: 1;
}

#modalReport button.close {
    opacity: 1;
    font-weight: 100;
}


/*  */

.div_mobile {
    display: none;
}

.mobile_video_info {
    display: none !important;
}

.mobile_video_info {
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.56px;
    color: #FFFFFF;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0.5rem;
    color: #fff;
    background: #00000069;
}

.mobile_video_info .avatar img {
    width: 45px;
    height: 45px;
    object-fit: cover;
    border-radius: 50%;
}

.iteam__schedule_livestream .live {
    position: absolute;
    top: 0 !important;
    right: 0 !important;
    border: none;
    box-shadow: none;
    border-radius: 0 !important;
    padding: 5px !important;
}

span.live.live_cus {
    left: 0;
    right: unset !important;
}

.iteam__schedule_livestream .live:focus {
    outline: 0;
}

.iteam__schedule_livestream .live.un_notification {
    background-color: #000000;
    opacity: 0.7;
    color: #FFF765;
}

.iteam__schedule_livestream .live.notification {
    background-color: #FFF765;
    color: #000000;
}

.iteam__schedule_livestream .live.notification.notification_cus {
    padding: 7px !important;
    font-size: 14px;
}

.iteam__schedule_livestream .timeLivestream {
    top: 0 !important;
    left: 0;
    border: none;
    box-shadow: none;
    border-radius: 0 !important;
    padding: 5px;
    position: absolute;
    background: #000000;
    opacity: 0.7;
    color: #ffffff;
    font-size: 10px;
}

a.button {
    background: none;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
    background-image: none;
}


/* livestream__menu */

.livestream__menu {
    padding: 8px 15px;
}

@media screen and (max-width:480px) {
    .sub__content span,
    .sub__content_reset span {
        font-size: 13px;
    }
    .list__livestream_login ul li span {
        font-size: 12px;
    }
    .loginGroup {
        display: flex;
        flex-direction: column;
    }
    .loginGroup li {
        margin-right: auto !important;
    }
    .login__form.register__form {
        width: 320px !important;
    }
    .box__chat {
        padding-bottom: 12px;
    }
    .user__actions .dropdown-menu {
        margin-top: 2px;
        margin-right: -21px;
    }
    
}

@media screen and (max-width: 320px) {
    .login__form.register__form {
        width: 290px !important;
    }
}