.img_baner {
    width: 100%;
    /* height: calc(100vh - 40px); */
    height: 582px;
}
@media screen and (max-width:768px) {
    .img_baner {
        height: 400px !important;
    }
}
@media screen and (max-width:480px) {
    .img_baner {
        height: 240px !important;
    }
}
@media screen and (max-width:320px) {
    .img_baner {
        height: 220px !important;
    }
}
@media screen and (max-width:320px) {
    .img_baner {
        height: 200px !important;
    }
}

