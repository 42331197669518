.event-edit-meta {
    text-decoration: none;
    list-style: none;
    padding-right: 2rem;
    width: 100%;
}
ul.event-edit-meta li {
    padding-top: 0.7rem;
    display: flex;
    align-items: center;
}
.event_edit_date_time {
    width: 99% !important;
}
/* event add ticket */
.event_btn_add_ticket {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
}
.btn_add_ticket {
    background-color: #FFF765;
    border-radius: 4px;
    font-size: 12px;
    font-weight: bold;
    padding: 0.4rem 2.5rem;
}

/* Modal create event */
.modal-content__event {
    margin: 0 auto !important;
    background-color: #383838 !important;
    color: #fff;
    border-radius: 0;
    width: 100% !important;
}
.create_event__title {
    display: block;
    text-align: center;
    font-size: 24px;
}
.create_event__content {
    width: 60%;
    display: block;
    margin: 1rem auto;
}

/* Modal create event */
.create-event-modal {
    position: relative;
    margin: 0 auto;
    top: 10%;
}

/*  */
.modal__input__datime {
    width: 100% !important;
}
.input__datepicker {
    width: 95%;
}

.modal__schedule-date-img {
    padding-right: 5px;
    margin-left: -3rem !important;
    width: unset !important;
}

@media (max-width: 480px) {
    .event_btn_add_ticket {
        margin-top: 40px;
    }
    .create_event__content {
        width: 80%;
    }
}
