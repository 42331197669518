.container_user_watched {
  flex-direction: column;
  padding-bottom: 15px;
  position: relative;
  margin-top: 10px;
}

.input_container_user {
  background: transparent;
  border: 1px solid #fff;
  width: 100%;
}

.dropdown__search_user {
  width: 100%;
  left: 0;
}

.user__watch_checkbox {
  margin-left: 10px;
  margin-right: 20px;
  margin-top: 8px;
}

.box__lists_user {
  margin-bottom: 10px;
  max-height: 200px;
  overflow-y: scroll;
}

.tag__lists_user {
  background-color: transparent;
  border: 1px solid #FFF765;
  margin-right: 5px;
  margin-bottom: 5px;
  font-size: 10px;
  padding: 3px 6px;
  border-radius: 0.25rem;
  display: inline-block;
}

.icon__close {
  margin-left: 5px;
}

.no__cursor {
  cursor: initial;
}
