/* .event-content-title {
    font-size: 35px;
    overflow-wrap: break-word;
} */
.event-infor {
    display: flex;
}
.event-meta-left {
    text-decoration: none;
    list-style: none;
    padding-right: 2rem;
    width: 100%;
}
.resize-icon {
    width: 1rem;
}

.event-meta-date {
    padding-left: 0.5rem;
}
ul.event-meta-left li {
    padding-top: 0.7rem;
    display: flex;
    align-items: center;
}
ul.event-meta-left li:nth-child(2) {
    display: block;
}
.badge-custom {
    margin-left: 0 !important;
    margin-right: 5px;
    margin-top: 5px;
    font-size: 10px;
}

.event__infor_user {
    padding: 1rem;
    width: 62%;
    color: #ffffff;
}
.btn__flow:hover, .btn__copy_url:hover {
    border: 1px solid #ffff00;
    color: #e7e3e3;
}

.event__infor_user .author__name {
    margin-top: 1rem;
}
.author__name h6 {
    font-size: 16px;
}
.event_follows {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    padding-top: 1rem;
}
.display_user_watch {
    display: block;
    width: 100%;
    margin-top: 10px;
}

@media (max-width: 414px) {
    .time_start__live {
        font-size: 14px;
    }
}
