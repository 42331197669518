p {
  margin-top: 200px;
}
p a {
    text-transform: uppercase;
    text-decoration: none;
    display: inline-block;
    color: #fff;
    padding: 5px 10px;
    margin: 0 5px;
    background-color: #495057;
}
p a:hover {
    background-color: #495057;
}

.select-hidden {
  display: none;
  visibility: hidden;
  padding-right: 10px;
}
.select {
  cursor: pointer;
  display: inline-block;
  position: relative;
  font-size: 16px;
  color: #495057;
  width: 100%;
  height: 40px;
}
.select-styled {
  position: absolute; 
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #ffffff;
  padding: 8px 15px;
}
.select-styled:after {
    content:"";
    width: 0;
    height: 0;
    border: 7px solid transparent;
    border-color: #495057 transparent transparent transparent;
    position: absolute;
    top: 16px;
    right: 10px;
}
.select-styled:active:after, .select-styled.active:after {
    top: 9px;
    border-color: transparent transparent #495057 transparent;
}
/* .select-styled:hover {
    background-color: #24b1c2;
} */
/* .select-styled:active, .select-styled.active {
    background-color: #24b1c2;
} */

.select-options {
    display: none; 
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    z-index: 999;
    margin: 0;
    padding: 0;
    list-style: none;
    background-color: #ffffff;
    border-top: 1px solid #495057;
}
.select-options li {
    margin: 0;
    padding: 12px 0;
    text-indent: 15px;
    border-top: 1px solid #495057;
}
.select-options li:hover {
    /* color: #24b1c2; */
    background: rgb(187, 182, 182);
}
/* .select-options li[rel="hide"] {
    display: none;
} */
