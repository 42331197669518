.list_progress_event_pm {
    width: 735px;
    margin: 1rem auto;
}
.progress__text_span {
    padding-right: 25px;
}
.progress__line_event {
    padding-left: 0.3875rem;
    padding-right: 0.375rem;
}
.ticket_active {
    color: #FFF765;
}

.container_buy_ticket fieldset {
    display: none;
}
.container_buy_ticket fieldset:first-child {
    display: block;
}
.progress__line_event .cicle_dots {
    position: relative;
    background: #FFFFFF;
    box-sizing: border-box;
    border: 1px solid #000000;
    box-shadow: 0px 2px 4px rgb(0 0 0 / 9%);
    width: 25px;
    height: 25px;
    border-radius: 50%;
}
.progress__line_event .cicle_dots1, .progress__line_event .cicle_dots2, .progress__line_event .cicle_dots3 {
    border: 1px solid #FFF765;
}
.progress__line_event .cicle_dots:before {
    position: absolute;
    content: "";
    width: 5px;
    height: 5px;
    border-radius: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 40%;
    background: #000000;
}
.progress__line_event .cicle_dots1:before, .progress__line_event .cicle_dots2:before, .progress__line_event .cicle_dots3:before {
    background: #FFF765;
}
.progress__line_event .line {
    border: 2px solid #FFFFFF;
    width: 40.515%;
}
.progress__line_event .line1, .progress__line_event .line2 {
    border: 2px solid #FFF765;
}

.input-event {
    background-color: transparent;
    color: #fff;
}
.input-event:focus {
    color: #fff;
    background-color: transparent;
    border-color: #fff;
    outline: 0;
    box-shadow: none;
}
.event-payment-desc {
    margin-top: 2.5rem;
    text-align: justify;
    background-color: #ffffff;
    color: #000;
    padding: 2rem;
    box-shadow: 0 0 0 2px rgb(255 255 255), 0em 0em 1em rgb(0 0 0 / 90%);
}
.event-payment-desc__title {
    text-align: center;
    display: block;
}
.event-payment-desc__hr {
    width: 60%;
    border: 1px solid #4A4A4A;
    margin-bottom: 2rem;
}
.event-payment-desc__but {
    display: block;
    text-align: center;
    margin-top: 2rem;
}
.event-payment-desc__but_return, .event-payment-desc__but_next {
    padding: 0.4rem 1.6rem;
    margin-right: 0.5rem;
    font-size: 12px;
    font-weight: bold;
}
.event-payment-desc__but_return {
    background-color: #9B9B9B;
}
.event-payment-desc__but_next {
    background-color: #FFF765;
}
.buy__ticket_payment, .buy__ticket_credit_card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
    color: #000;
}
.buy__ticket_payment_cus {
    align-items: inherit;
}
.apply_purchase__buy__ticket {
    border-bottom: 2px solid #a4a4a4;
    font-size: 13px;
}
.buy__ticket_header {
    width: 100%;
    margin-top: 1rem;
    display: flex;
}
.buy__ticket_img {
    width: 30%;
}
.buy__ticket_img img {
    width: 100%;
    height: 90px;
}
.buy__ticket_content {
    width: 70%;
    padding-left: 1rem;
}
.buy__ticket_content_tit {
    display: block;
    font-size: 14px;
    font-weight: bold;
}
.buy__ticket_content_cal {
    display: block;
    color: #9B9B9B;
    font-size: 12.42px;
    margin-top: 1.5rem;
}
.buy__ticket_content_cal img {
    padding-right: 0.5rem;
}
.btn-event__details {
    display: block;
    background-color: #FFF765;
    width: 100%;
    margin: 2.2rem 0 1.2rem;
    font-size: 12px;
    font-weight: bold;
}
.buy__ticket_sp {
    display: flex;
    justify-content: space-between;
    /* font-weight: bold; */
}
.margin-bottom-1rem {
    margin-bottom: 1rem;
}
.buy__ticket_total {
    font-weight: 500;
    font-size: 16px;
    padding-top: 0.5rem;
    width: 100%;
}
.buy__ticket_width_60 {
    width: 60%;
    text-align: right;
}
.margin__top_2 {
    margin-top: 2rem;
}
.ticket-payment__hr {
    width: 100%;
    border-bottom: 2px solid #a4a4a4;
}
.ticket-payment__img_card {
    padding: 0 1rem;
}
.ticket-payment__img_card img {
    width: 60px;
}
.buy__ticket_credit_card {
    font-size: 13px;
    font-weight: bold;
}
.credit_card_content {
    width: 60%;
    margin-bottom: 0.5rem;
}
.margin-2rem-15rem {
    margin-top: 2rem;
    margin-bottom: 1.5rem;
}
/* radio */
.credit-card__radio {
    display: inline-flex;
    width: 20%;
}
.credit-card__radio input[type="radio"] {
    display: none;
    /* margin: 3px 8px 3px 0; */
}
.credit-card__radio label {
	position: relative;
    display: inline-block;
    padding: 8px 3px 3px 28px;
    cursor: pointer;
}
.credit-card__radio label::before {
    position: absolute;
    content: '';
    top: 50%;
    left: 0;
    width: 22px;
    height: 22px;
    margin-top: -8px;
    background: #fff;
    border: 1px solid #FFF765;
    border-radius: 100%;
}
.credit-card__radio input[type="radio"]:checked + label::after {
	position: absolute;
    content: '';
    top: 50%;
    left: 4px;
    width: 16px;
    height: 16px;
    margin-top: -5px;
    margin-left: -1px;
    background: #FFF765;
    border-radius: 100%;
}
/*  */
.buy__ticket_img_success {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}
.buy__ticket_img_success img {
    width: 18%;
}
.ticket_success_font {
    font-size: 15px;
    font-weight: bold;
}
.payment__img_card {
    justify-content: center;
    margin-bottom: 1rem;
}
.buy__ticket_btn_success {
    background-color: #FFF765;
    margin-bottom: 1.5rem;
    padding: 8px 30px;
    font-size: 12px;
    font-weight: bold;
}
.payment__padding_top_1 {
    padding-top: 1rem;
    text-align: center;
}
.no-bold {
    font-weight: normal;
}
.event-content .buy__ticket.buy__ticket_payment {
    margin-top: 2rem;
}

/*  */
.apply_purchase_custom {
    border-bottom: 3px solid #a4a4a4 !important;
    text-align: center;
}
.box__pad_bottom {
    padding-bottom: 1rem;
}

.agree{
    display: flex;
    margin: 15px 10px;
}

.invalid__feedback_pd {
    padding-left: 15px;
}

@media (max-width: 480px) {
    .list_progress_event_pm {
        width: 100%;
    }
    .event-payment-desc {
        padding: 1rem 0.5rem;
    }
    .buy__ticket_sp span:nth-child(1){
        text-align: left;
    }
    .buy__ticket_width_60 {
        width: 95%;
    }
    .ticket_success_font {
        font-size: 14px;
        text-align: center;
    }
    .buy__ticket_total {
        font-size: 14px;
    }
    .buy__ticket_content_cal img {
        width: 25px;
    }
}

