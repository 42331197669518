/* Charge coin */
.section__charge_coins { min-height: 100vh; padding: 3rem 0;}
.tabs_charge_coin { width: 350px; margin: 0 auto; }
.list_charge__coin li span { font-size: 18px; line-height: 25px; color: #FFFFFF; }
.tabs_charge_coin h5 strong { font-size: 18px; line-height: 25px; color: #FFFFFF; letter-spacing: 5px; }
.tabs_charge_coin .note { padding: 0.5rem 0; }
.tabs_charge_coin h5 { margin: 0; }
.tabs_charge_coin h5, .tabs_charge_coin { font-style: normal; font-weight: normal; font-size: 16px; line-height: 25px; color: #FFFFFF; }
.list_charge__coin { padding: 0.5rem 0; margin: 0; }
.list_charge__coin li { padding: 5px 0; list-style: none; display: flex; justify-content: space-between; align-items: center; }
.list_charge__coin li .money img { padding-right: 0.5rem; }
.list_charge__coin li .money span { font-size: 17px; line-height: 19px; color: #FFFFFF; border: 1px solid #FFCC00; padding: 8px 19px; border-radius: 5px; display: block; font-weight: bold; }
.coin { padding-top: 8px; }
.remind-notification { text-align: center; margin: 10px auto; display: block; }
.remind-notification a { text-decoration: underline; }
