.video-js .vjs-menu-button-inline.vjs-slider-active,.video-js .vjs-menu-button-inline:focus,.video-js .vjs-menu-button-inline:hover,.video-js.vjs-no-flex .vjs-menu-button-inline {
    width: 10em!important;
}

.video-js .vjs-controls-disabled .vjs-big-play-button {
    display: none!important
}

.video-js .vjs-menu-button-inline:before {
    width: 1.5em!important
}

.vjs-menu-button-inline .vjs-menu {
    left: 3em!important
}

.vjs-paused.vjs-has-started.video-js .vjs-big-play-button,.video-js.vjs-ended .vjs-big-play-button,.video-js.vjs-paused .vjs-big-play-button {
    display: block!important
}

.video-js .vjs-load-progress div,.vjs-seeking .vjs-big-play-button,.vjs-waiting .vjs-big-play-button {
    display: none!important
}

.video-js .vjs-mouse-display:after,.video-js .vjs-play-progress:after {
    padding: 0 .4em .3em!important
}

.video-js.vjs-ended .vjs-loading-spinner {
    display: none!important;
}

.video-js.vjs-ended .vjs-big-play-button {
    display: block !important;
}

.video-js *,.video-js:after,.video-js:before {
    box-sizing: inherit!important;
    font-size: inherit!important;
    color: inherit!important;
    line-height: inherit
}

/* .video-js.vjs-fullscreen,.video-js.vjs-fullscreen .vjs-tech {
    width: 100%!important;
    height: 100%!important
} */

.video-js {
    font-size: 14px!important;
    overflow: hidden!important
}

/* .video-js .vjs-control {
    color: inherit
} */

.video-js .vjs-menu-button-inline:hover,.video-js.vjs-no-flex .vjs-menu-button-inline {
    width: 8.35em!important
}

.vjs-volume-tooltip {
    display: none;
}

.video-js .vjs-volume-menu-button.vjs-volume-menu-button-horizontal:hover .vjs-menu .vjs-menu-content {
    height: 3em!important;
    width: 6.35em!important
}

.video-js .vjs-control:focus:before,.video-js .vjs-control:hover:before {
    text-shadow: 0 0 1em #fff,0 0 1em #fff,0 0 1em #fff!important
}

.video-js .vjs-spacer,.video-js .vjs-time-control {
    display: -webkit-box!important;
    display: -moz-box!important;
    display: -ms-flexbox!important;
    display: -webkit-flex!important;
    display: flex!important;
    -webkit-box-flex: 1 1 auto!important;
    -moz-box-flex: 1 1 auto!important;
    -webkit-flex: 1 1 auto!important;
    -ms-flex: 1 1 auto!important;
    flex: 1 1 auto!important
}

.video-js .vjs-time-control {
    -webkit-box-flex: 0 1 auto!important;
    -moz-box-flex: 0 1 auto!important;
    -webkit-flex: 0 1 auto!important;
    -ms-flex: 0 1 auto!important;
    flex: 0 1 auto!important;
    width: auto
}

.video-js .vjs-time-control.vjs-time-divider {
    width: 14px!important;
}

.video-js .vjs-time-control.vjs-time-divider div {
    width: 100%!important;
    text-align: center!important;
}

/* .video-js .vjs-time-control.vjs-current-time {
    margin-left: 1em
} */

.video-js .vjs-time-control .vjs-current-time-display,.video-js .vjs-time-control .vjs-duration-display {
    width: 100%!important;
}

.video-js .vjs-time-control .vjs-current-time-display {
    text-align: right!important;
}

.video-js .vjs-time-control .vjs-duration-display {
    text-align: left!important;
}

.video-js .vjs-play-progress:before,.video-js .vjs-progress-control .vjs-play-progress:before,.video-js .vjs-remaining-time,.video-js .vjs-volume-level:after,.video-js .vjs-volume-level:before,.video-js.vjs-live .vjs-time-control.vjs-current-time,.video-js.vjs-live .vjs-time-control.vjs-duration,.video-js.vjs-live .vjs-time-control.vjs-time-divider,.video-js.vjs-no-flex .vjs-time-control.vjs-remaining-time {
    display: none!important;
}

.video-js.vjs-no-flex .vjs-time-control {
    display: table-cell!important;
    width: 4em!important;
}

.video-js .vjs-progress-control {
    position: absolute!important;
    left: 0!important;
    right: 0!important;
    width: 100%!important;
    height: .5em!important;
    top: -.5em!important;
}

/* .video-js .vjs-progress-control .vjs-load-progress,.video-js .vjs-progress-control .vjs-play-progress,.video-js .vjs-progress-control .vjs-progress-holder {
    height: 100%
} */

/* .video-js .vjs-progress-control .vjs-progress-holder {
    margin: 0
} */

.video-js .vjs-progress-control:hover {
    height: 1.5em!important;
    top: -1.5em!important;
}

.video-js .vjs-control-bar {
    -webkit-transition: -webkit-transform .1s ease 0s!important;
    -moz-transition: -moz-transform .1s ease 0s!important;
    -ms-transition: -ms-transform .1s ease 0s!important;
    -o-transition: -o-transform .1s ease 0s!important;
    transition: transform .1s ease 0s!important;
}

.video-js.not-hover.vjs-has-started.vjs-paused.vjs-user-active .vjs-control-bar,.video-js.not-hover.vjs-has-started.vjs-paused.vjs-user-inactive .vjs-control-bar,.video-js.not-hover.vjs-has-started.vjs-playing.vjs-user-active .vjs-control-bar,.video-js.not-hover.vjs-has-started.vjs-playing.vjs-user-inactive .vjs-control-bar,.video-js.vjs-has-started.vjs-playing.vjs-user-inactive .vjs-control-bar {
    visibility: visible!important;
    opacity: 1!important;
    -webkit-backface-visibility: hidden!important;
    -webkit-transform: translateY(3em)!important;
    -moz-transform: translateY(3em)!important;
    -ms-transform: translateY(3em)!important;
    -o-transform: translateY(3em)!important;
    transform: translateY(3em)!important;
    -webkit-transition: -webkit-transform 1s ease 0s!important;
    -moz-transition: -moz-transform 1s ease 0s!important;
    -ms-transition: -ms-transform 1s ease 0s!important;
    -o-transition: -o-transform 1s ease 0s!important;
    transition: transform 1s ease 0s!important;
}

.video-js.not-hover.vjs-has-started.vjs-paused.vjs-user-active .vjs-progress-control,.video-js.not-hover.vjs-has-started.vjs-paused.vjs-user-inactive .vjs-progress-control,.video-js.not-hover.vjs-has-started.vjs-playing.vjs-user-active .vjs-progress-control,.video-js.not-hover.vjs-has-started.vjs-playing.vjs-user-inactive .vjs-progress-control,.video-js.vjs-has-started.vjs-playing.vjs-user-inactive .vjs-progress-control {
    height: .25em!important;
    top: -.25em!important;
    pointer-events: none!important;
    -webkit-transition: height 1s,top 1s!important;
    -moz-transition: height 1s,top 1s!important;
    -ms-transition: height 1s,top 1s!important;
    -o-transition: height 1s,top 1s!important;
    transition: height 1s,top 1s !important
}

.video-js.not-hover.vjs-has-started.vjs-paused.vjs-user-active.vjs-fullscreen .vjs-progress-control,.video-js.not-hover.vjs-has-started.vjs-paused.vjs-user-inactive.vjs-fullscreen .vjs-progress-control,.video-js.not-hover.vjs-has-started.vjs-playing.vjs-user-active.vjs-fullscreen .vjs-progress-control,.video-js.not-hover.vjs-has-started.vjs-playing.vjs-user-inactive.vjs-fullscreen .vjs-progress-control,.video-js.vjs-has-started.vjs-playing.vjs-user-inactive.vjs-fullscreen .vjs-progress-control {
    opacity: 0!important;
    -webkit-transition: opacity 1s ease 1s!important;
    -moz-transition: opacity 1s ease 1s!important;
    -ms-transition: opacity 1s ease 1s!important;
    -o-transition: opacity 1s ease 1s!important;
    transition: opacity 1s ease 1s !important
}

.video-js.vjs-live .vjs-live-control {
    margin-left: 1em!important;
}

.video-js .vjs-big-play-button {
    top: 49.9%!important;
    left: 50%!important;
    margin-left: -1em!important;
    margin-top: -1em!important;
    width: 2em!important;
    height: 2em!important;
    line-height: 2em!important;
    border: 0!important;
    /* border-radius: 50%!important; */
    font-size: 3.5em!important;
    background-color: rgba(0,0,0,.45)!important;
    color: #fff!important;
    -webkit-transition: border-color .4s,outline .4s,background-color .4s!important;
    -moz-transition: border-color .4s,outline .4s,background-color .4s!important;
    -ms-transition: border-color .4s,outline .4s,background-color .4s!important;
    -o-transition: border-color .4s,outline .4s,background-color .4s!important;
    transition: border-color .4s,outline .4s,background-color .4s!important;
}

.video-js .vjs-menu-button-popup .vjs-menu {
    left: -3em!important;
}

.video-js .vjs-menu-button-popup .vjs-menu .vjs-menu-content {
    background-color: transparent!important;
    width: 12em!important;
    left: -1.5em!important;
    padding-bottom: .5em!important;
}

.video-js .vjs-menu-button-popup .vjs-menu .vjs-menu-item,.video-js .vjs-menu-button-popup .vjs-menu .vjs-menu-title {
    background-color: #151b17!important;
    margin: .3em 0!important;
    padding: .5em!important;
    /* border-radius: .3em */
}

.video-js .vjs-menu-button-popup .vjs-menu .vjs-menu-item.vjs-selected {
    background-color: #2483d5!important;
}

.video-js .vjs-big-play-button {
    background-color: rgba(0,0,0,0.5)!important;
    font-size: 2.5em!important;
    border-radius: 10%!important;
    height: 1.43em !important;
    line-height: 1.43em !important;
    margin-top: -0.7em !important
}

.video-js:hover .vjs-big-play-button,.video-js .vjs-big-play-button:focus,.video-js .vjs-big-play-button:active {
    background-color: #cc181e!important;
}

.video-js .vjs-loading-spinner {
    border-color: #cc181e!important;
}

.video-js .vjs-control-bar2 {
    background-color: #000000!important;
}

.video-js .vjs-control-bar {
    background-color: rgba(0,0,0,0.3) !important;
    color: #ffffff!important;
    font-size: 12px!important;
}

.video-js .vjs-play-progress,.video-js  .vjs-volume-level {
    background-color: #cc181e!important;
}

.video-js .vjs-load-progress {
    background: rgba(255,255,255,0.3)!important;
}

/* videojs */
.vjs-seek-to-live-text {
    color:  #cc181e!important;
}
.vjs-seek-to-live-control .vjs-icon-placeholder:before {
    color: #cc181e!important;
}
.vjs-play-control, .vjs-mute-control, .vjs-seek-to-live-control, .vjs-fullscreen-control, .vjs-big-play-button {
    border: none !important;
    outline: none !important;
}

.comment-form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.comment__coin {
    width: 1.3rem;
    padding-bottom: 2px;
    padding-right: 5px;
}

.video__stop_livestream {
    opacity: 0.9;
    z-index: 1000;
    background-color: rgba(0,0,0,0.75);
}

.stop_box__video {
    flex: 0 0 0;
    opacity: 0.9;
    z-index: 1000;
    color: #fff;
    min-height: 650px;
    background-color: rgba(0,0,0,0.75);
}

.stop_display_text {
    position: absolute;
    width: 100%;
    height: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    background-color: rgba(0,0,0,0.75);
    /* background-color: #000000; */
}

.vjs-fluid {
    padding-top: 0 !important;
}

.vjs-live-display {
    color: #cc181e!important;
}

@media screen and (max-width:992px) {
    .box__video {
        min-height: unset !important;
    }
    .video__stop_livestream_mobile .stop_box__chat1 {
        top: 0;
    }
    .stop_box__chat1 {
        flex: 0 0 0;
        opacity: 0.9;
        z-index: 1000;
        color: #fff;
        background-color: rgba(0,0,0,0.75);
        position: absolute;
        width: 100%;
        height: 100%;
    }
    .box__chat_text {
        position: relative;
        top: 45%;
        text-align: center;
    }
    .stop_box__livestream {
        position: relative;
        width: 100%;
        height: 100%;
        display: contents;
    }

    .video__stop_livestream {
        position: absolute;
        top: 50%;
        color: #fff;
        width: 100%;
        text-align: center;
    }
}
@media screen and (max-width:768px) {
    .video__stop_livestream_mobile .stop_box__chat1 {
        top: unset;
    }
    .video__stop_livestream_mobile .box__chat_text {
        top: 46%;
    }
    .video__stop_livestream_mobile {
        position: absolute;
        top: 0;
        left: 0;
        color: #fff;
        width: 100%;
        height: 100%;
        padding-top: unset;
        /* padding-left: 0%; */
    }
    .video__stop_livestream {
        position: absolute;
        top: 50%;
        color: #fff;
        width: 100%;
        text-align: center;
    }

    .stop_box__chat1 {
        flex: 0 0 0;
        opacity: 0.9;
        z-index: 1000;
        color: #fff;
        background-color: rgba(0,0,0,0.75);
        position: absolute;
        width: 100%;
        height: 100%;
    }
    .stop_box__chat2 {
        flex: 0 0 0;
        opacity: 0.9;
        z-index: 1000;
        color: #fff;
        background-color: rgba(0,0,0,0.75);
        position: absolute;
        width: 100%;
    }
    .box__chat_text {
        position: relative;
        top: 50%;
        text-align: center;
    }
    .vjs-fluid {
        padding-top: 49% !important;
    }
}
@media screen and (max-width:480px) {
    .stop_box__video {
        min-height: unset;
    }
    .video__stop_livestream {
        position: absolute;
        top: 44%;
        color: #fff;
        font-size: 14px;
    }
    .stop_box__chat2 {
        flex: 0 0 0;
        opacity: 0.9;
        z-index: 1000;
        color: #fff;
        background-color: rgba(0,0,0,0.75);
        position: absolute;
        width: 100%;
    }
    .box__chat_text {
        font-size: 14px;
        opacity: 0.9;
        margin: 5px;
    }
    .video__stop_livestream_mobile .box__chat_text {
        top: 44%;
    }
}
@media screen and (max-width:320px) {
    .stop_box__chat2 {
        flex: 0 0 0;
        opacity: 0.9;
        z-index: 1000;
        color: #fff;
        background-color: rgba(0,0,0,0.75);
        position: absolute;
        width: 100%;
    }
    .video__stop_livestream_mobile .box__chat_text {
        top: 39%;
    }
}
