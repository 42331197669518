html .home__page_live {
    font-size: 16px;
}
body .home__page_live .container {
    font-size: 16px;
    width: 100%;
}

fieldset {
    border: 0;
}

#modalShare,
#modalReport {
    z-index: 2000;
}

.home__page_live {
    height: 100%;
    min-height: 100vh;
}

a {
    cursor: pointer;
}

.spinner-margin-right {
    margin-right: -1.35rem !important;
}

.img-avatar, html.homepage .img-avatar {
    width: 25px;
    height: 25px;
    /* padding-left: 3px; */
    border-radius: 50%;
}

html.homepage .navbar {
    padding: 8px 16px;
}

.locate__input {
    cursor: pointer;
}

.locate__input .select__phone {
    padding: 16px 35px 16px 18px;
    margin-right: 6px;
}

.input__select .caret {
    top: 24px;
    right: 14px;
    pointer-events: none;
    color: #9B9B9B;
}

.input__phone.input__phone__number {
    flex: 0 0 60%;
}

.input__phone__number>input {
    width: 100% !important;
}

.sprinner-register {
    margin-right: -1.35rem !important;
}

.video_not_fount {
    display: flex;
    align-items: center;
    height: 50px;
    text-align: center;
    width: 100%;
    color: #ffffff;
    opacity: 0.7;
    margin-bottom: 1rem;
}

.input__select__phone {
    flex: 0 0 38%;
}

.video_not_fount .video_span_not_fount {
    width: 100%;
}

.margin_verify input {
    margin: 0 5px;
}

.nav-tabs .nav-link {
    border: 0 solid transparent;
}

.video__box img {
    height: 125px;
}

.user_infor img {
    width: 3rem;
    height: 3rem;
    object-fit: cover;
    border-radius: 50%;
}

.video__livestream {
    display: contents;
}

.videoPlay-dimensions.vjs-fluid {
    padding-top: 75.333333% !important;
}

.video-js {
    width: 100%;
    height: 100% !important;
}

#videoPlay {
    padding-top: 75.333333% !important;
}

input.type__comment_input {
    color: #fff;
    font-size: 14px;
    width: 80%;
    padding: 1px;
}

button#sentMess {
    display: contents;
    width: 9%;
}

img.user__avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.user-name {
    font-size: 14px;
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: end;
}

.fa-font-weight {
    font-weight: 600
}

.li-backgroud {
    background: #000000 !important;
}

.box__lis_chat {
    height: 85%;
    overflow-y: auto;
    flex-grow: 1;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: thin;
    scrollbar-color: #212529 #d3d3d3;
}

.bonus_item_span {
    display: block;
}

.avatar__coins_notifi {
    width: 3rem;
    border-radius: 50%;
    padding: 0.5rem;
}

.img_coin__coins_notifi {
    width: 3rem;
    padding: 0.5rem;
}

.Toastify__toast--default {
    background: #262626;
    opacity: 0.8;
    color: #ffffff;
    border-bottom-right-radius: 80px 80px;
    border-top-right-radius: 80px 80px;
    border: 1px solid #ffffff;
}

.span__coins_notifi {
    margin-left: 1rem;
    color: yellow;
}

.breadcrumb-item+.breadcrumb-item::before {
    display: inline-block;
    padding-right: 0;
    color: #ffffff;
    content: "\f054";
    font-weight: 600;
}

.breadcrumb-item+.breadcrumb-item {
    padding-left: 0;
    font-family: "Font Awesome 5 Free";
}

.breadcrumb-color {
    color: #ffffff;
    font-size: 16px;
    margin-bottom: 1px;
}

.breadcrumb-color:hover {
    color: #aeb1b5;
}

.Toastify__toast-container {
    width: 240px;
}

.Toastify__toast-container--top-left {
    margin-top: 0em;
    margin-left: 0em;
    position: absolute;
    width: auto;
}

.Toastify__toast {
    min-height: unset;
    padding: 0;
    padding-right: 1rem;
    box-shadow: unset;
    border: 1px solid #fff;
}

.Toastify__toast--default,
.Toastify__toast--default {
    opacity: 1;
}

/* report modal */
.invalid-feedback-modal {
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #dc3545;
}

.reveal-modal {
    position: relative;
    margin: 0 auto;
    top: 20%;
}

#modalReport .modal-title,
#modalShare .modal-title {
    margin-left: 40px;
}

#modalReport .submit-margin-right {
    margin-right: 15px;
    padding: 10px 26px;
    border-radius: 0;
    margin-bottom: 0.3rem;
}

textarea#comment {
    border-radius: 0;
}

.modal-header {
    height: 40px;
    align-items: center;
}

.modal-content.modal-content__custom {
    width: 100%;
}

.btn-custom-like {
    padding-bottom: 0.8rem;
}

.inputform-body {
    display: flex;
    justify-content: center;
}

.inputform-body input {
    margin: 0 0.5rem;
    font-weight: bold;
}
#modalChargeCoins .modal-content {
    background-color: #212121;
}
#modalChargeCoins .modal-title{
    color: #fff ;
    text-align: center;
    line-height: 1;
    width: 100%;
    padding-left: 30px;
}
#modalChargeCoins button.close{
    color: #fff ;
    opacity: 1;
    font-size: 20px;
}
#modalChargeCoins .modal-content-custom {
    color: #fff;
    text-align: center;
    line-height: 1;
    width: 100%;
    display: block;
}

.ic__coin_ld {
    padding-top: 3px;
}

.list__video_related{
    overflow-y: auto;
}

/* Emoji like */
.icon__like {
    width: 20px;
}
.icon__like_mobile {
    width: 16px;
}

@media screen and (max-width:992px) {
    .Toastify__toast-body {
        font-size: 12px;
    }

    .Toastify__toast-body .span__coins_notifi {
        font-size: 16px;
    }

    .Toastify__toast-container--top-left {
        top: 5px;
        left: 5px;
    }

    .type__comment form {
        width: 100%;
        height: 2rem;
    }

    input.type__comment_input {
        width: 92%;
    }

    .video__box img {
        height: 11rem;
    }
}

@media screen and (max-width:768px) {
    #showBoxChat {
        display: none !important;
    }

    input.type__comment_input {
        width: 90%;
    }

    .video__box img {
        height: 10rem;
    }

    .Toastify__toast-container--top-left {
        width: auto;
    }
    select.form-control.modal-select {
        font-size: 12px;
    }

    .select {
        font-size: 12px;
    }
    .select-styled {
        padding: 10px 15px;
    }
}

@media screen and (max-width:480px) {
    .Toastify__toast {
        margin-left: -20px;
    }
    .Toastify__toast-body {
        font-size: 10px;
    }

    .Toastify__toast-body .span__coins_notifi {
        font-size: 13px;
    }

    .avatar__coins_notifi,
    .img_coin__coins_notifi {
        width: 2rem;
        padding: 0.3rem;
    }

    .span__coins_notifi {
        margin-left: 0.5rem;
    }

    input.type__comment_input {
        width: 90%;
        padding-right: 10px;
    }

    .video__box img {
        height: 12rem;
    }

    .type__comment form {
        height: 1.5rem;
    }
    .breadcrumb-item button{
        padding: .375rem;
    }
    .breadcrumb-color{
        font-size: 14px;
    }
    .breadcrumb-item button span{
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        line-height: 1;
    }
    .stream_description .btn__follow_chanel {
        padding: 5px 10px;
        font-size: 12px;
    }
    .select {
        font-size: 11px;
    }
    .select-styled {
        padding: 12px 10px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-right: 20px;
    }
    .select-options li {
        padding: 12px 10px;
        text-indent: unset;
    }
    .user_infor img {
        width: 2.5rem;
        height: 2.5rem;
    }
    .video_not_fount {
        font-size: 14px;
    }
    html.homepage .navbar {
        padding: 0;
    }
}

@media screen and (max-width:320px) {
    .stream_description .btn__follow_chanel {
        padding: 4px 7px;
        font-size: 11px;
    }
    .video_not_fount {
        font-size: 12px;
    }
}