.my-page-content {
    margin: 0 auto;
    width: 60%;
}
.my_page_infor_user {
    padding: 3rem 0;
    color: #ffffff;
}
.my_page_infor_user_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.my_page_infor_user_img {
    width: 30%;
}
.my_page_infor_user_img img {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    border: 1px solid #fff;
}
.user_profile__name {
    font-size: 16px;
    padding-top: 5px;
}
.btn_user_profile {
    border: 1px solid;
    color: #ffffff;
    margin: 0 0.5rem;
    border-radius: 4px;
    font-size: 14px;
}
.btn_user_profile:hover {
    border: 1px solid;
    color: #ffffff;
}
.tick_user_profile {
    margin: 0 0.5rem;
    width: 20px;
}
.user_profile_content {
    font-size: 14px;
    width: 100%;
}
.user_profile_url {
    display: block;
    color: #4A90E2;
}
.user_profile_follows {
    width: 100%;
    font-size: 14px;
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.user_profile_follows span{
    padding-right: 3rem;
}
#tab_user_profile {
    width: 100%;
    border-bottom: 1px solid #979797;
    margin: 1rem 0;
}
#tab__list_user_profile {
    border-bottom: 1px solid transparent;
    width: 96%;
    margin: 0 auto;
}
.nav-item_user_profile {
    margin-bottom: -1px;
    width: 50%;
    text-align: center;
}
.nav-link_user_profile {
    color: #ffffff;
    display: block;
    padding: .5rem 1rem;
}
.nav-link_user_profile:hover {
    color: #ffffff;
}
.user_profile__active {
    border-bottom: 2px solid #FFF765;
    color: #FFF765;
    margin-bottom: -1px;
}
.tab-content {
    width: 100%;
}
.live_content, .schedule_history_content {
    width: 96%;
    margin: 0 auto;
}
#tab__list_user_profile_schedule {
    border-bottom: 1px solid transparent;
    width: 100%;
    margin: 0 auto;
    background-color: #D8D8D8;
}
.user_profile__active_sub {
    background-color: #FFF765;
}
.nav-link_user_profile_sub {
    color: #000000;
    font-size: 14px;
    display: block;
    padding: .5rem 1rem;
}
.nav-link_user_profile_sub:hover {
    color: #000000;
}
#tab_user_profile_sub {
    width: 100%;
    margin: 1rem 0;
}
.schedule_content, .history_content, .live_content_tab1, .live_content_tab2 {
    background-color: #5E5E5E;
    display: flex;
    width: 100%;
    padding: 1rem;
    margin-bottom: 1rem;
}

.schedule_content_img {
    width: 120px;
}
.schedule_content_img img{
    width: 120px;
    height: 150px;
    object-fit: cover;
}
.schedule_content_box {
    width: 85%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 1rem;
}
span.schedule_time_day {
    padding-bottom: 1rem;
}
.schedule_content_time, .schedule_content_live {
    padding-right: 2rem;
    font-size: 16px;
}
.schedule_day {
    padding-right: 0.5rem;
}
.schedule_comment {
    font-size: 16px;
    word-break: break-all;
}

.sprinner-my-page {
    width: 2rem;
    height: 2rem;
    border-width: .2em;
    margin: 0 auto;
    display: block;
    padding-top: 1rem;
}
.color__sprinner {
    color: #ffffff;
}
.sprinner_loading-center {
    padding-top: 35%;
    padding-bottom: 35%;
}
.sprinner-center {
    width: 2rem;
    height: 2rem;
    border-width: .2em;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
}
@media (max-width: 812px) {
    .user_profile_follows span {
        padding-right: 2rem;
    }
    .my-page-content {
        width: 80%;
    }
}
@media (max-width: 768px) {
    .schedule_content_img img {
        width: 110px;
        height: 140px;
    }
    .schedule_content_box {
        width: 75%;
    }
    .schedule_content_time, .schedule_content_live {
        padding-right: 1.5rem;
        font-size: 13px;
    }
    .schedule_comment {
        font-size: 13px;
    }
}
@media (max-width: 414px) {
    .user_profile_follows span:nth-child(3n) {
        display: inline-block;
    }
    /* .my-page-content {
        width: 80%;
    } */
    .schedule_content, .history_content, .live_content_tab1, .live_content_tab2 {
        flex-direction: column;
    }
    .schedule_content_img, .schedule_content_box {
        width: 100%;
    }
    .schedule_content_box {
        padding-left: 0;
    }
    .schedule_content_img img {
        width: 100%;
        height: 250px;
    }
    span.schedule_time_day {
        padding-top: 1rem;
    }
    .schedule_content_time, .schedule_content_live {
        padding-right: 0.5rem;
        font-size: 12px;
    }
    .schedule_comment {
        font-size: 12px;
    }
}
