.banner_position {
    position: relative;
}
.img_baner {
    /* width: 100%;
    height: 582px; */
}
.banner_title {
    color: #ffffff;
    font-size: 36px;
    font-weight: normal;
    position: absolute;
    top: 23px;
    left: 30px;
}
.title__create_schedule {
    color: #ffffff;
    font-size: 30px;
    font-weight: normal;
    padding: 1rem;
    display: block;
    text-align: center;
}
