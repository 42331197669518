.event-content {
    margin: 0 auto;
    padding: 2rem 1rem;
    color: #ffffff;
}

/* button */
.event__btn {
    display: flex;
    justify-content: space-evenly;
}
.event__btn_free_ticket, .event__but_next {
    background-color: #FFF765;
}
.event__but_return {
    background-color: #9B9B9B;
}
.event__but_return, .event__but_next, .event__btn_free_ticket {
    padding: 0.4rem;
    font-size: 12px;
    font-weight: bold;
    width: 250px;
}

/* Modal Give Away Ticket */
.give_away_ticket__title {
    text-align: center;
    display: block;
    font-size: 28px;
    font-weight: bold;
}
.give_away_ticket__btn {
    padding: 0.4rem 1rem !important;
    width: 140px;
}
.give_away_ticket__released {
    text-align: center;
}

/* Modal create schedule */
.btn.schedule__but_return.give_away_ticket__btn.close__public {
    z-index: 99999;
}

@media (max-width: 812px) {
    .event__but_return, .event__but_next, .event__btn_free_ticket {
        width: 200px;
    }
}
@media (max-width: 480px) {
    .event-content {
        padding: 1.4rem 0.3rem;
    }
    .give_away_ticket__btn {
        width: 127px;
    }
}
@media (max-width: 414px) {
    .event__but_return, .event__but_next, .event__btn_free_ticket {
        width: 100px;
    }
    .event__but_cus {
        width: unset;
        padding: 7px 14px;
    }
}
@media (max-width: 320px) {
    .event__but_cus {
        font-size: 10px;
    }
}
